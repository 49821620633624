<template>
  <div id="app">
    <div id="vue-confirm-dialog"></div>
    <div class="page__bd" style="height: 100%; width:100%; position: absolute;bottom: 0;">
      <div class="weui-tab">
        <div id="panel1" role="tabpanel" aria-labelledby="tab1" class="weui-tab__panel">
          <router-view></router-view>
        </div>
        <tabbar v-bind:activeUrlname="activeUrlname"></tabbar>
      </div>
    </div>
  </div>
</template>

<script>
import tabbar from "./components/tabbar.vue"

export default {
  name: 'App',
  components: {
    tabbar
  },
  data () {
    console.log("this.$route.name", this.$route.name);
    return {activeUrlname: this.$route.name}
  },
  mounted: function () {
    var sessionidFromQuery = this.$route.query.sessionid;
    if (sessionidFromQuery != undefined) {
      this.$cookies.set("sessionid", sessionidFromQuery, "2050-01-01");
      this.$router.push({
        name: this.$route.name,
      });
    }
  },
}
</script>

<style>
:focus-visible {
  outline: none;
} 
</style>
