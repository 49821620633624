import { request } from "./api";

const ORDER_USAGE_API = "/web_api/orders/{}/usages"
const USAGE_API = "/web_api/usages/{}"
const FREE_ORDER_USAGE_API = "/web_api/usages/create_free_order_usage"
const USAGES_FOR_COACH = "/web_api/usages/for_coach"
const USAGE_FOR_COACH = "/web_api/usages/get_single_for_coach"
const TAKEN_BY_COACH = "/web_api/usages/{}/taken"
const UNTAKEN_BY_USER = "/web_api/usages/{}/untaken"
const FINISH_USAGE = "/web_api/usages/{}/finish"
const REFUSE_USAGE = "/web_api/usages/{}/refuse"
const UPLOAD_QRCODE = "/web_api/usages/{}/upload_qr_code"


async function getOrderUsages(orderId) {
    return await request("get", ORDER_USAGE_API.replace("{}", orderId), {}, {});
}

async function createOrderUsage(orderId, usage) {
    return await request("post", ORDER_USAGE_API.replace("{}", orderId), usage, {});
}

async function createFreeOrderUsage(usage) {
    return await request("post", FREE_ORDER_USAGE_API, usage, {});
}

async function deleteUsage(usageId) {
    return await request("delete", USAGE_API.replace("{}", usageId), {}, {});
}

async function getUsagesForCoach(status) {
    return await request("get", USAGES_FOR_COACH, {}, {
        status: status
    });
}
async function getSingleUsageForCoach(usageId) {
    return await request("get", USAGE_FOR_COACH, {}, {
        usage_id: usageId
    });
}
async function takenByCoach(usageId) {
    return await request("post", TAKEN_BY_COACH.replace("{}", usageId), {}, {});
}

async function untakenByUser(usageId) {
    return await request("post", UNTAKEN_BY_USER.replace("{}", usageId), {}, {});
}

async function finishUsage(usageId, qr_code_file_path) {
    return await request("post", FINISH_USAGE.replace("{}", usageId), {
        qr_code_file_path: qr_code_file_path,
    }, {});
}

async function refuseUsage(usageId) {
    return await request("post", REFUSE_USAGE.replace("{}", usageId), {}, {});
}

async function uploadQRCode(usageId, file) {
    var formData = new FormData();
    formData.append("file", file);
    return await request("post", UPLOAD_QRCODE.replace("{}", usageId), formData, {});
}

export {
    getOrderUsages,
    createOrderUsage,
    deleteUsage,
    getUsagesForCoach,
    getSingleUsageForCoach,
    takenByCoach,
    untakenByUser,
    finishUsage,
    refuseUsage,
    createFreeOrderUsage,
    uploadQRCode,
}
