<template>
  <div class="goods-item-card" @click="clickGoods">
    <div class="price">
      <span class="actual-price">{{ actualPrice }}</span>
      <span class="origin-price">{{ originPrice }}</span>
    </div>
    <div class="title">{{ title }}</div>
    <div class="desr">{{ desr }}</div>
    <div class="expire-desr">{{ expireDesr }}</div>
  </div>
</template>

<script>
export default {
  name: "goodsItem",
  props: {
    actualPrice: {
      type: Number,
      default: 0,
    },
    originPrice: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "defaultTitle",
    },
    desr: {
      type: String,
      default: "defaultDescr",
    },
    expireDesr: {
      type: String,
      default: "defaultExpireDesr",
    },
    goodsId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    clickGoods: function () {
      console.log(this.goodsId);
      this.$router.push({
        path: `/purcharse/${this.goodsId}`,
      });
    },
  },
};
</script>

<style>
.goods-item-card {
  background: white;
  border-radius: 15px;
  padding: 15px;
}
.actual-price {
  font-size: 1.5em;
  color: #09bb07;
  margin-right: 10px;
}
.actual-price::before {
  content: "¥";
  font-size: 1em;
}
.origin-price {
  font-size: 0.8em;
  color: #999;
  text-decoration: line-through;
}
.desr, .expire-desr {
  font-size: 0.8em;
  color: #999;
}
</style>
