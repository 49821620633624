<template>
  <div>
    <h1>{{ title }}</h1>
    <div class="article-content" v-html="content">
    </div>
  </div>
</template>

<script>
import { getArticleOne } from "../js/article.js";

export default {
  name: "article",
  props: {},
  components: {
  },
  data: function () {
    return {
      articleId: undefined,
      title: "loading...",
      content: "loading...",
    };
  },
  async mounted() {
    this.articleId = this.$route.params.articleId;
    var article = await getArticleOne(this.articleId);
    this.title = article.title;
    this.content = article.content;
  },
  methods: {
  }
};
</script>

<style>
</style>
