<template>
  <div class="page" style="margin-top:15px">
    <loading-icon :sources="usages"></loading-icon>

    <div v-for="usage in usages" v-bind:key="usage.id"  class="order-usage-item-box">
      <orderUsageItem
        v-bind:id="usage.id"
        v-bind:goodsName="usage.goods.name"
        v-bind:encrypt_id="usage.encrypt_id"
        v-bind:orderId="usage.orderId"
        v-bind:datetime="usage.datetime"
        v-bind:usage_duration="usage.usage_duration"
        v-bind:user_contact="usage.user_contact"
        v-bind:address="usage.address"
        v-bind:course_focus="usage.course_focus"
        v-bind:special_requirement="usage.special_requirement || '无'"
        v-bind:coach="usage.coach"
        v-bind:designatedCoach="usage.designated_coach"
        v-bind:status="usage.status"
        v-on:finishUsage="finishUsage($event)"
        v-on:deleteUsage="deleteUsage($event)"
        v-on:userUntakeUsage="userUntakeUsage($event)"
        class="order-usage-item"
      ></orderUsageItem>
    </div>
  </div>
</template>

<script>
import orderUsageItem from '../components/order_usage_item.vue';
import { getOrderUsages, untakenByUser } from '../js/order_usage';
import { finishUsage, deleteUsage } from '../js/order_usage';
import loadingIcon from '../components/loading_icon';

var usages = null;

export default {
  name: "orderUsage",
  props: {},
  components: {
    orderUsageItem,
    loadingIcon,
  },
  data: function () {
    return {
      usages,
    };
  },
  async mounted() {
    this.usages = await getOrderUsages(this.$route.params.orderId);
  },
  methods: {
    finishUsage: function (usageId) {
      var isFreeGoodsUsage = this.usages.find(function (usage) {
        return usage.id === usageId;
      }).goods.is_free;

      if (!isFreeGoodsUsage) {
        if (confirm("确认完成此记录？")) {
          finishUsage(usageId).then(function () {
            location.reload();
          }).catch(function (err) {
            alert(err);
          });
        }
      }
      else {
        var orderId = this.$route.params.orderId;
        window.location.href = `/order/${orderId}/usages/${usageId}/finish_free_goods_usage`;
      }
    },
    userUntakeUsage: function (usageId) {
      if (confirm("确认取消此订单？")) {
        untakenByUser(usageId).then(function () {
          location.reload();
        }).catch(function (err) {
          alert(err);
        });
      }
    },
    deleteUsage: function (usageId) {
      if (confirm("确认取消此预约？")) {
        deleteUsage(usageId).then(function () {
          location.reload();
        }).catch(function (err) {
          alert(err);
        });
      }
    }
  }
};
</script>

<style scoped>
.order-usage-item-box {
  padding: 0 15px;
  margin-bottom: 15px;
  border-radius: 15px;
}
.order-usage-item-box,
.order-usage-item {
  border-radius: 15px;
}
.order-usage-item-box,
.order-usage-item::before,
.order-usage-item-box,
.order-usage-item::after {
  border: 0;
}
</style>
