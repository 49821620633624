<template>
  <div class="page" style="margin-top:15px">
    <navbar
      v-bind:title1="'抢单（' + usagesWaitingForTakingLength + '）'"
      v-bind:title2="'待服务（' + usagesWaitingForServingLength + '）'"
      v-bind:title3="'已完成（' + usagesCompetedLength + '）'"
      v-on:click1="showUsagesWaitingForTaking"
      v-on:click2="showUsagesWaitingForServing"
      v-on:click3="showUsagesCompeted"
      class="order-navbar"
    ></navbar>
    <loading-icon :sources="usagesDisplayed"></loading-icon>

    <div v-for="usage in usagesDisplayed" v-bind:key="usage.id"  class="order-usage-item-box">
      <orderUsageItem
        v-bind:isCoachView="true"
        v-bind:is_designated="!!usage.designated_coach_id"
        v-bind:id="usage.id"
        v-bind:goodsName="usage.goods.name"
        v-bind:encrypt_id="usage.encrypt_id"
        v-bind:orderId="usage.orderId"
        v-bind:datetime="usage.datetime"
        v-bind:usage_duration="usage.usage_duration"
        v-bind:user_name="usage.user_name"
        v-bind:user_contact="usage.user_contact"
        v-bind:address="usage.address"
        v-bind:course_focus="usage.course_focus"
        v-bind:special_requirement="usage.special_requirement || '无'"
        v-bind:coach="usage.coach"
        v-bind:designatedCoach="usage.designated_coach"
        v-bind:status="usage.status"
        v-on:coachTakeUsage="coachTakeUsage($event)"
        v-on:coachRefuseUsage="coachRefuseUsage($event)"
        class="order-usage-item"
      ></orderUsageItem>
    </div>
  </div>
</template>

<script>
import { getUsagesForCoach, takenByCoach, refuseUsage } from "../js/order_usage";
import navbar from "../components/navbar.vue";
import orderUsageItem from '../components/order_usage_item.vue';
import loadingIcon from '../components/loading_icon';

var usagesDisplayed = null;
var usagesWaitingForTaking = null;
var usagesWaitingForServing = null;
var usagesCompeted = null;

export default {
  name: "usageForCoach",
  props: {},
  components: {
    navbar,
    orderUsageItem,
    loadingIcon,
  },
  data: function () {
    return {
      usagesDisplayed,
      usagesWaitingForTaking,
      usagesWaitingForServing,
      usagesCompeted,
      usagesWaitingForTakingLength: "...",
      usagesWaitingForServingLength: "...",
      usagesCompetedLength: "...",
    };
  },
  async mounted() {
    this.usagesWaitingForServing = await getUsagesForCoach(0);
    this.usagesWaitingForTaking = await getUsagesForCoach(-1);

    this.usagesWaitingForServingLength = this.usagesWaitingForServing.length;
    this.usagesWaitingForTakingLength = this.usagesWaitingForTaking.length;

    this.usagesDisplayed = this.usagesWaitingForTaking;
  },
  methods: {
    showUsagesWaitingForTaking: function () {
      this.usagesDisplayed = this.usagesWaitingForTaking;
    },
    showUsagesWaitingForServing: async function () {
      this.usagesDisplayed = this.usagesWaitingForServing;

      if (this.usagesWaitingForServing == null) {
        this.usagesWaitingForServing = await getUsagesForCoach(0);
        this.usagesDisplayed = this.usagesWaitingForServing;
        this.usagesWaitingForServingLength = this.usagesWaitingForServing.length;
      }
    },
    showUsagesCompeted: async function () {
      this.usagesDisplayed = this.usagesCompeted;

      if (this.usagesCompeted == null) {
        this.usagesCompeted = await getUsagesForCoach(1);
        this.usagesDisplayed = this.usagesCompeted;
        this.usagesCompetedLength = this.usagesCompeted.length;
      }
    },
    coachTakeUsage: function (usageId) {
      if (confirm("确认抢单？")) {
        takenByCoach(usageId).then(function () {
          location.reload();
        }).catch(function (err) {
          alert(err);
        });
      }
    },
    coachRefuseUsage: function (usageId) {
      if (confirm("确认拒绝此订单？")) {
        refuseUsage(usageId).then(function () {
          location.reload();
        }).catch(function (err) {
          alert(err);
        });
      }
    }
  }
};
</script>

<style scoped>
.order-usage-item-box {
  padding: 0 15px;
  margin-bottom: 15px;
  border-radius: 15px;
}
.order-usage-item-box,
.order-usage-item {
  border-radius: 15px;
}
.order-usage-item-box,
.order-usage-item::before,
.order-usage-item-box,
.order-usage-item::after {
  border: 0;
}
</style>