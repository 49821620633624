<template>
  <div>
    <h1>{{ title }}</h1>
    <div class="article-content" v-html="content">
    </div>
    <div class="weui-panel__bd card">
      <div class="weui-media-box weui-media-box_small-appmsg">
        <div class="weui-cells">
          <a v-for="article in articles" v-bind:key="article.id" class="weui-cell weui-cell_access" v-bind:href="['/article/' + article.id]">
            <div class="weui-cell__bd weui-cell_primary">
              <p>{{ article.title }}</p>
            </div>
            <span class="weui-cell__ft"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticleList } from "../js/article.js";

export default {
  name: "articleList",
  props: {},
  components: {
  },
  data: function () {
    return {
      articles: []
    };
  },
  async mounted() {
    this.articles = await getArticleList()
  },
  methods: {
  }
};
</script>

<style>
</style>
