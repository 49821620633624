<template>
  <div
    id="dialogWrap3"
    class="js_dialog_wrap"
    ref="showDialog3"
    aria-label="弹窗标题"
    role="dialog"
    aria-modal="false"
    aria-hidden="true"
    style="display: none"
  >
    <div aria-label="关闭" role="button" class="js_close weui-mask"></div>
    <div
      id="js_dialog_3"
      class="js_dialog weui-half-screen-dialog weui-half-screen-dialog_large"
    >
      <div class="weui-half-screen-dialog__bd">
        <loading-icon :sources="coaches"></loading-icon>
        <coach-card v-for="coach in coaches" :key="coach.id"
          v-bind:hasSelected="coach.id == selectedCoachId"
          v-bind:id="coach.id"
          v-bind:name="coach.name"
          v-bind:headimg="coach.headimg"
          v-bind:description="coach.description || '暂无描述'"
          style="margin-bottom: 15px"
          v-on:selected="coachSelected"
        ></coach-card>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import coachCard from './coach_card.vue'
import { getCoaches } from '../js/coach.js'
import loadingIcon from '../components/loading_icon';

var coaches = null;
var selectedCoachId = false;

export default {
  components: { coachCard, loadingIcon },
  name: "coachSelectDialog",
  async mounted () {
    var that = this;
    $('.js_close').on('click', function() {
      that.hideDialog();
      console.log("hidddde");
    });
    this.coaches = await getCoaches();

    // NOTE: 把测试教练隐藏一下
    this.coaches = this.coaches.filter(function(coach) {
      return coach.name != "测试专用，请勿选我";
    });
  },
  methods: {
    coachSelected: function (coachId, coachName, coachHeadimg) {
      this.selectedCoachId = coachId;
      this.$emit('coachSelected', coachId, coachName, coachHeadimg);
      this.hideDialog();
    },
    hideDialog: function() {
      const $jsDialogWrap = $('.js_dialog_wrap');
      $jsDialogWrap.attr('aria-hidden','true').attr('aria-modal','false').removeAttr('tabindex');
      $jsDialogWrap.fadeOut(300);
      $jsDialogWrap.find('.js_dialog').removeClass('weui-half-screen-dialog_show');
      setTimeout(function(){
        $('#' + $jsDialogWrap.attr('ref')).trigger('focus');
      }, 300);
    }
  },
  data () {
      return {
        coaches,
        selectedCoachId,
      }
  },
  props: {
  }
};
</script>

<style>
#js_dialog_3 {
  top: 30%;
  padding-top: 24px;
}

</style>
