<template>
  <div class="page bind-phone-box">
    <div class="weui-form">
      <div class="weui-form__text-area">
        <h2 class="weui-form__title">绑定手机号 并选择城市</h2>
      </div>
      <div class="weui-form__control-area">
        <div class="weui-cells__group weui-cells__group_form">
          <div class="weui-cells">
              <div class="weui-cell weui-cell_active">
                  <div class="weui-cell__hd"><label for="phone-input" class="weui-label">手机号</label></div>
                  <div class="weui-cell__bd">
                      <input id="phone-input" v-model="phone" class="weui-input" type="number" pattern="[0-9]*" placeholder="请输入手机号"/>
                  </div>
                  <div class="weui-cell__ft">
                    <button type="button" id="showIOSDialog1" class="weui-btn_reset weui-btn_icon">
                      <i role="img" alt="帮助" class="js_target weui-icon-info-circle"></i>
                    </button>
                  </div>
              </div>
              <div id="js_cell_vcode" class="weui-cell weui-cell_active weui-cell_vcode weui-cell_wrap">
                  <div class="weui-cell__hd"><label for="sms-code-input" class="weui-label">验证码</label></div>
                  <div class="weui-cell__bd weui-flex">
                    <input
                      id="sms-code-input"
                      class="weui-input weui-cell__control weui-cell__control_flex"
                      type="text" pattern="[0-9]*" placeholder="请输入" maxlength="6"
                      v-model="smsCode"
                    />
                    <button
                      id="js_btn_vcode" role="button"
                      class="js_target weui-cell__control weui-btn weui-btn_default weui-vcode-btn"
                      v-on:click="clickToSendSmsCode"
                    >{{ sendSmsBtnText }}</button>
                  </div>
              </div>
              <div class="weui-cell">
                <div class="weui-cell__hd"><label class="weui-label">选择城市</label></div>
                <div class="weui-cell__bd">
                  <div v-on:click="changeCity('南京市')" class="city-radio city-radio-南京市 active" style="margin-right:10px">南京</div>
                  <div v-on:click="changeCity('武汉市')" class="city-radio city-radio-武汉市">武汉</div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="weui-form__opr-area">
        <button class="weui-btn weui-btn_primary" type="button" id="showTooltips" v-on:click="submitPhone">{{ addPhoneButtonText }}</button>
      </div>
    </div>
    <div role="alert" id="js_toast" style="display: none;">
        <div class="weui-mask_transparent"></div>
        <div class="weui-toast">
            <i class="weui-icon-success-no-circle weui-icon_toast"></i>
            <p class="weui-toast__content">已完成</p>
        </div>
    </div>
  </div>
</template>

<script>
import {
  getUserFromLocalStorage, clearUserFromLocalStorage,
  sendSmsCode, verifySmsCode
} from "../js/user";
import $ from "jquery";
import { updateUserCity } from "../js/user";

export default {
  name: "bindLogin",
  props: {},
  components: {},
  data: function () {
    return {
      phone: "",
      smsCode: "",
      addPhoneButtonText: "确定",
      sendSmsBtnText: "获取",
    };
  },
  async mounted() {
    // 主要起到检查登录态的作用
    var user = await getUserFromLocalStorage();
    this.phone = user.phone;
  },
  methods: {
    submitPhone: async function () {
      var phone = this.phone;
      var smsCode = this.smsCode;
      if (!phone) {
        alert("请输入手机号");
        return;
      }
      if (phone.length != 11) {
        alert("手机号格式错误");
        return;
      }
      if (!smsCode) {
        alert("请输入验证码");
        return;
      }

      try {
        this.addPhoneButtonText = "正在绑定……";
        await verifySmsCode(phone, smsCode);
        // 为了刷新 localstorage 的 user 信息
        clearUserFromLocalStorage();
        this.addPhoneButtonText = "绑定成功！";
      } catch (e) {
        this.addPhoneButtonText = "确定";
        throw e;
      }

      // sleep 1s then redirect
      setTimeout(() => {
        var redirect_uri = this.$route.query.service || "/user";
        window.location.href = redirect_uri;
      }, 500);
    },
    clickToSendSmsCode: async function () {
      if (this.sendSmsBtnText != "获取") {
        return;
      }

      var phone = this.phone;
      if (!phone) {
        alert("请输入手机号");
        return;
      }
      if (phone.length != 11) {
        alert("手机号格式错误");
        return;
      }
      await sendSmsCode(phone);

      var count = 60;
      this.sendSmsBtnText = `${count}s`;
      var timer = setInterval(() => {
        count--;
        this.sendSmsBtnText = `${count}s`;
        if (count == 0) {
          clearInterval(timer);
          this.sendSmsBtnText = '获取';
        }
      }, 1000);
    },
    changeCity(city) {
      $(".active").removeClass("active");
      $(`.city-radio-${city}`).addClass("active");
      updateUserCity(city);
    },
  },
};
</script>

<style>
#sms-code-input {
  height: 32px;
}
#js_btn_vcode {
  width: -webkit-fill-available;
}
.bind-phone-box {
  padding: 15px;
}
.bind-phone-box .weui-form {
  border-radius: 15px;
}
.weui-form__opr-area:last-child {
  margin-bottom: 56px;
}

.city-radio{
  padding: 5px 8px;
  border-radius: .25rem;
  display: inline;
  border: 1px solid #d2d2d2;
  margin-right: 3px;
}

.city-radio.active{
  background: #0cc160;
  color: white;
  border: 1px solid #7fd39a;
}
</style>
