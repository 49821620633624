import { request } from "./api";

const ADMIN_USAGE = `/web_api/admin/finish_usage`

async function getAllCoachUsage() {
    var response = await request("get", ADMIN_USAGE, {}, {});
    return response;
}

async function patchAdminFinishUsage(usageId, coachId) {
    console.log("patchAdminFinishUsage", usageId, coachId);
    var response = await request("patch", ADMIN_USAGE, {
        usage_id: usageId,
        coach_id: coachId,
    }, {});
    return response;
}

export {
    getAllCoachUsage,
    patchAdminFinishUsage,
}
