<template>
  <div class="page" style="margin-top:15px">
    <div style="padding: 15px;">
      <button
        v-for="count,coachName in coachNamesAndCount" v-bind:key="coachName"
        class="weui-btn weui-btn_mini weui-btn_default weui-wa-hotarea"
        style="margin: 0 5px 5px 0;"
        v-on:click="showSingleCoachUsages(coachName)"
      >
        {{ coachName }}({{ count }})
      </button>
    </div>

    <loading-icon :sources="actualUsages"></loading-icon>

    <div v-for="usage in actualUsages" v-bind:key="usage.id"  class="order-usage-item-box">
      <adminFinishUsageItem
        v-bind:id="usage.id"
        v-bind:goodsName="usage.goods.name"
        v-bind:encrypt_id="usage.encrypt_id"
        v-bind:orderId="usage.order.id"
        v-bind:datetime="usage.datetime"
        v-bind:usage_duration="usage.usage_duration"
        v-bind:user_name="usage.user.name"
        v-bind:user_contact="usage.user_contact"
        v-bind:address="usage.address"
        v-bind:course_focus="usage.course_focus"
        v-bind:special_requirement="usage.special_requirement || '无'"
        v-bind:coach="usage.coach"
        v-bind:status="usage.status"
        v-on:adminFinishUsage="adminFinishUsage"
        class="order-usage-item"
      ></adminFinishUsageItem>
    </div>
  </div>
</template>

<script>
import { getAllCoachUsage, patchAdminFinishUsage } from "../js/admin_finish_usage";
import adminFinishUsageItem from "../components/admin_finish_usage_item.vue";
import loadingIcon from '../components/loading_icon';
import $ from 'jquery'

var usages = null;
var actualUsages = null;
var coachNamesAndCount = [];

export default {
  name: "adminFinishUsage",
  async mounted () {
    this.usages = await getAllCoachUsage();
    this.actualUsages = this.usages;
    this.coachNamesAndCount = this.computedCoachNamesAndCount();
  },
  methods: {
    showSingleCoachUsages (coachName) {
      this.actualUsages = null;

      $(".weui-btn_primary").addClass("weui-btn_default");
      $(".weui-btn_primary").removeClass("weui-btn_primary");
      $(".weui-wa-hotarea").each(function () {
        if ($(this).text().indexOf(coachName + '(') != -1) {
          $(this).removeClass("weui-btn_default");
          $(this).addClass("weui-btn_primary");
        }
      });

      setTimeout(() => {
        this.actualUsages = this.usages.filter(usage => usage.coach.name == coachName);
      }, 200);
    },
    computedCoachNamesAndCount () {
      // Return {"coachName": count}
      var coachNames = {};
      for (var i = 0; i < this.usages.length; i++) {
        var coachName = this.usages[i].coach.name;
        if (coachNames[coachName] == undefined) {
          coachNames[coachName] = 1;
        } else {
          coachNames[coachName] += 1;
        }
      }
      return coachNames;
    },
    async adminFinishUsage (id, coachId) {
      console.log("admin_finish_usage.vue adminFinishUsage", id, coachId);
      if (confirm("确定要强制完成该订单吗？") == false) {
        return;
      }

      await patchAdminFinishUsage(id, coachId);

      // Remove it from usages & actualUsages
      for (var i = 0; i < this.usages.length; i++) {
        if (this.usages[i].id == id) {
          this.usages.splice(i, 1);
          break;
        }
      }
      for (i = 0; i < this.actualUsages.length; i++) {
        if (this.actualUsages[i].id == id) {
          this.actualUsages.splice(i, 1);
          break;
        }
      }
      // refresh coach count
      this.coachNamesAndCount = this.computedCoachNamesAndCount();
    },
  },
  data() {
    return {
      usages,
      actualUsages,
      coachNamesAndCount,
    };
  },
  components: {
    adminFinishUsageItem,
    loadingIcon
  },
};
</script>

<style scoped>
.order-usage-item-box {
  padding: 0 15px;
  margin-bottom: 15px;
  border-radius: 15px;
}
.order-usage-item-box,
.order-usage-item {
  border-radius: 15px;
}
.order-usage-item-box,
.order-usage-item::before,
.order-usage-item-box,
.order-usage-item::after {
  border: 0;
}
</style>
