<template>
  <div class="padding-15">
    <div class="goods-item" style="margin-bottom: 15px">
      <goodsItem
        v-bind:actualPrice="actualPrice"
        v-bind:originPrice="originPrice"
        v-bind:title="goodsName"
        v-bind:desr="description"
        v-bind:goodsId="goodsId"
        expireDesr="有效期：购买日期起 500 天"
      ></goodsItem>
    </div>

    <div class="user-select-box" style="margin-bottom: 15px" v-if="isAdmin">
      <div class="weui-cell">
        <div class="weui-cell__hd"><label class="weui-label">学员</label></div>
        <div class="weui-cell__bd">
          <select class="user-select" style="border: 0;font-size: inherit;height: 24px; width: 100%">
            <option value="">请选择学员</option>
            <template v-for="user in users">
              <option v-bind:value="user.id" v-bind:key="user.id">{{ user.name }}</option>
            </template>
          </select>
        </div>
      </div>
      <div class="weui-cell">
        <div class="weui-cell__bd">
            <input id="phone-input" class="weui-input" type="number" pattern="[0-9]*" placeholder="或者在此输入电话号码" />
        </div>
      </div>
    </div>

    <div class="pay-box">
      <div class="page">
        <div class="weui-form" style="border-radius: 15px">
          <div class="weui-form__text-area">
            <h2 class="weui-form__title">请选择支付方式</h2>
          </div>
          <div class="weui-form__control-area">
            <div class="weui-cells__group weui-cells__group_form">
              <div class="weui-cells weui-cells_radio">
                <label
                  class="weui-cell weui-cell_active weui-check__label"
                  for="wechatpay"
                >
                  <div class="weui-cell__bd">
                    <p>微信</p>
                  </div>
                  <div class="weui-cell__ft">
                    <input
                      type="radio"
                      class="weui-check"
                      name="radio1"
                      id="wechatpay"
                      checked="checked"
                    />/>
                    <span class="weui-icon-checked"></span>
                  </div>
                </label>
                <label
                  class="weui-cell weui-cell_active weui-check__label"
                  for="alipay"
                >
                  <div class="weui-cell__bd">
                    <p>支付宝（暂不支持）</p>
                  </div>
                  <div class="weui-cell__ft">
                    <input
                      type="radio"
                      name="radio1"
                      class="weui-check"
                      id="alipay"
                    />
                    <span class="weui-icon-checked"></span>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="weui-form__opr-area">
            <a
              role="button"
              @click="pay"
              class="weui-btn weui-btn_primary"
              href="javascript:"
              id="showTooltips"
              >支付 {{ actualPrice }} 元</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoodsOne } from "../js/goods";
import { createPrePayOrder } from "../js/prepay";
import { wechatPay } from "../js/wechat_pay";
import goodsItem from "../components/goods_item.vue";
import $ from "jquery";
import { getUserFromLocalStorage } from '../js/user.js'
import { getUsers } from "../js/user";

export default {
  name: "purcharse",
  props: {},
  data() {
    return {
      goodsId: undefined,
      goodsName: "",
      originPrice: undefined,
      actualPrice: undefined,
      courseDuration: undefined,
      city: "",
      carType: "",
      description: "",
      useWechatPay: true,
      useAliPay: false,
      isAdmin: false,
      users: [],
    };
  },
  mounted: async function () {
    var goods = await getGoodsOne(this.$route.params.goodsId);
    this.goodsId = goods.id;
    this.goodsName = goods.name;
    this.originPrice = goods.origin_price;
    this.actualPrice = goods.actual_price;
    this.courseDuration = goods.course_duration;
    this.city = goods.city;
    this.carType = goods.car_type;
    this.description = goods.description;

    var user = await getUserFromLocalStorage();
    this.isAdmin = user.isAdmin;

    if (this.isAdmin) {
      this.actualPrice = 0.01;
      this.users = await getUsers();
    }
  },
  components: {
    goodsItem,
  },
  methods: {
    async pay() {
      var checkId = $(".weui-check:checked").attr("id");
      var useAliPay = checkId == "alipay";
      if (useAliPay) {
        alert("暂不支持支付宝支付");
        return;
      }

      var response = null;
      if (this.isAdmin) {
        var user_id = $(".user-select").val();
        var user_phone = $("#phone-input").val();
        if (!user_id && !user_phone) {
          alert("请选择学员");
          throw new Error;
        }
        if (user_phone && user_phone.length != 11) {
          alert("电话号码格式错误");
          throw new Error;
        }
        response = await createPrePayOrder(this.goodsId, user_id, user_phone);
      } else {
        response = await createPrePayOrder(this.goodsId);
      }

      console.log("createPrePayOrder.response", response);
      wechatPay(response, () => {
        this.$router.push({
          path: `/order`,
        });
      });
    },
  },
};
</script>

<style scoped>
.padding-15 {
  padding: 15px;
}
/* .purcharse-title {
  margin-bottom: 15px;
} */
.weui-form__opr-area {
  margin-bottom: 56px;
}

.user-select-box {
  background: white;
  border-radius: 15px;
}
</style>
