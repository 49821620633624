<template>
  <div></div>
</template>

<script>
  import { getOrders } from "../js/order";

  export default {
    name: "script",
    props: {},
    data: function () {
      return {}
    },
    async mounted () {
      var scriptName = this.$route.params.scriptName;

      if (scriptName == "call") {
        var phone = this.$route.query.phone;
        if (phone) {
          window.location.href = "tel:" + phone;
        }
      }
      else if (scriptName == "jump_to_usage_add") {
        this.$router.push(`/order/${await this.getLastOrderId()}/usage_add`);
      }
      else if (scriptName == "jump_to_usages") {
        this.$router.push(`/order/${await this.getLastOrderId()}/usage`);
      }
      else {
        alert("未知的脚本：" + scriptName);
      }
    },
    methods: {
      async getLastOrderId () {
        // 如果多个 order，选剩余量最多的那个。如果一样多，选最新的一个
        var orders = await getOrders();
        if (orders.length == 0) {
          alert("您还没有购买任何课时");
          this.$router.push("/");
        }
        orders.sort(function (a, b) {
          if (a.last_usage == b.last_usage) {
            return b.id - a.id;
          }
          return b.last_usage - a.last_usage;
        });
        var lastOrder = orders[0];
        return lastOrder.id;
      }
    }
  }
</script>
