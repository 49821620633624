<template>
  <div class="">
    <div class="weui-cells weui-cells_form">
      <div class="weui-cell">
        <div class="weui-cell__hd"><label class="weui-label">联系方式</label></div>
        <div class="weui-cell__bd"><input class="weui-input contact-input" type="string" placeholder="请输入电话、微信或其他"/></div>
      </div>
      <div class="weui-cell">
        <div class="weui-cell__hd"><label for="" class="weui-label">开始时间</label></div>
        <div class="weui-cell__bd"><input class="weui-input datetime-input" type="datetime-local" :max="maxDateTime()" :min="minDateTime()" value="" placeholder=""/></div>
      </div>
      <div class="weui-cell">
        <div class="weui-cell__hd"><label class="weui-label">预约时长</label></div>
        <div class="weui-cell__bd">
          <div v-on:click="changeDuration(2)" class="hour-radio hour-radio2 active">2小时</div>
          <div v-on:click="changeDuration(3)" class="hour-radio hour-radio3">3小时</div>
          <div v-on:click="changeDuration(4)" class="hour-radio hour-radio4">4小时</div>
          <input class="duration-input" type="number" hidden value=2 />
        </div>
      </div>
      <div class="weui-cell  weui-cell_select" style="padding: 0 16px">
        <div class="weui-cell__hd">
          <label class="weui-label">练习内容</label>
        </div>
        <div class="weui-cell__bd">
            <select class="weui-select course-focus" name="select1" style="text-align: left; padding:0;">
                <option selected="" value="道路行驶为主">道路行驶为主</option>
                <option value="倒车入库为主">倒车入库为主</option>
                <option value="侧方停车为主">侧方停车为主</option>
                <option value="夜间行驶为主">夜间行驶为主</option>
                <option value="快速路为主">快速路为主</option>
            </select>
        </div>
      </div>
      <div class="weui-cell">
        <div class="weui-cell__hd"><label class="weui-label">所在地区</label></div>
        <v-distpicker
          v-if="city == '南京市'"
          :province-disabled="true"
          :city-disabled="true"
          :province="'江苏省'"
          :city="'南京市'"
        ></v-distpicker>
        <v-distpicker
          v-if="city == '武汉市'"
          :province-disabled="true"
          :city-disabled="true"
          :province="'湖北省'"
          :city="'武汉市'"
        ></v-distpicker>
      </div>
          <div class="weui-cell">
            <div class="weui-cell__bd">
              <textarea class="weui-textarea address-input-detail" placeholder="详细地址" rows="1"></textarea>
              <div class="weui-textarea-counter"><span>0</span>/100</div>
            </div>
          </div>
    </div>

    <div class="weui-cells weui-cells_form">
      <div class="weui-cell">
        <div class="weui-cell__hd"><label class="weui-label">指定教练</label></div>
        <div class="weui-cell__bd" v-on:click="showUsageRecords">
          <input class="weui-input display-coach-input" placeholder="指定一个教练，非必填"/>
          <input hidden class="weui-input coach-input"/>
        </div>
      </div>
      <div class="weui-cells weui-cells_form" style="margin-top:0">
          <div class="weui-cell">
            <div class="weui-cell__bd">
                <textarea class="weui-textarea special_requirement" placeholder="备注你的特殊要求，非必填。比如：我只接受女教练" rows="3"></textarea>
                <div class="weui-textarea-counter"><span>0</span>/200</div>
            </div>
          </div>
      </div>
    </div>

    <coach-select-dialog
      v-on:coachSelected="onCoachSelected"
    ></coach-select-dialog>

    <div class="weui-btn-area">
        <a class="weui-btn weui-btn_primary" v-on:click="createUsage">确定</a>
      </div>
  </div>
</template>

<script>
import $ from "jquery";
import { createOrderUsage } from "../js/order_usage";
import coachSelectDialog from "../components/coach_select_dialog.vue";
import { showDialog } from "../js/order_usage_records_dialog";
import VDistpicker from 'v-distpicker'
import { getUserFromLocalStorage } from "../js/user";

export default {
  name: "orderUsageAdd",
  async mounted() {
    var user = await getUserFromLocalStorage();
    this.city = user.city;
  },
  methods: {
    showUsageRecords () {
      console.log("showUsageRecords clicked");
      showDialog();
    },
    async createUsage() {
      if (this.creating) {
        return;
      }

      this.getFormData(); // Just for validating params
      console.log(this.getFormData());
      if (confirm("确定创建新的预约？")) {
        this.creating = true;
        $(".weui-btn_primary").text("创建中...");
        var orderId = this.$route.params.orderId;
        try {
          await createOrderUsage(orderId, this.getFormData());
          this.$router.push(`/order/${orderId}/usage`);
        } finally {
          this.creating = false;
          $(".weui-btn_primary").text("确定");
        }
      }
    },
    getFormData() {
      var user_contact = $(".contact-input").val();
      var datetime = $(".datetime-input").val();
      var usage_duration = $(".duration-input").val();
      var course_focus = $(".course-focus").val();
      var province = $(".distpicker-address-wrapper label:nth-child(1) select").val();
      var city = $(".distpicker-address-wrapper label:nth-child(2) select").val();
      var area = $(".distpicker-address-wrapper label:nth-child(3) select").val();
      var addressDetail = $(".address-input-detail").val();
      var designated_coach_id = $(".coach-input").val();
      var special_requirement = $(".special_requirement").val();

      if (!user_contact) {
        alert("联系方式 不能为空");
        throw new Error;
      }
      if (!datetime) {
        alert("开始时间 不能为空");
        throw new Error;
      }
      if (!usage_duration) {
        alert("预约时长 不能为空");
        throw new Error;
      }
      if (!province || province == "省") {
        alert("省份 不能为空");
        throw new Error; 
      }
      if (!city || city == "市") {
        alert("城市 不能为空");
        throw new Error;
      }
      if (!area || area == "区") {
        alert("区 不能为空");
        throw new Error;
      }
      if (!addressDetail) {
        alert("详细地址 不能为空");
        throw new Error;
        
      }
      usage_duration = parseInt(usage_duration) * 60;
      if (usage_duration % 1 != 0) {
        alert("预约时长 必须是整数");
        throw new Error;
      }

      return {
        user_contact,
        datetime,
        usage_duration,
        'address': `${province}-${city}-${area} ${addressDetail}`,
        course_focus,
        designated_coach_id,
        special_requirement,
      };
    },
    carSelectChanged() {
      console.log(123);
    },
    onCoachSelected(coachId, coachName, coachHeadimg) {
      console.log("selsect coachId", coachId, coachName, coachHeadimg);
      $(".display-coach-input").val(coachName);
      $(".coach-input").val(coachId);
    },
    changeDuration(hour) {
      $(".duration-input").val(hour);
      $(".active").removeClass("active");
      $(`.hour-radio${hour}`).addClass("active");
    },
    changeCourseFocus(courseFocus) {
      $(".course-focus-radio.active").removeClass("active");
      $(`.course-focus-radio.${courseFocus}`).addClass("active");
      $(`div:contains(${courseFocus})`).addClass("active");
    },
    maxDateTime() {
      var today = new Date();
      var maxDate = new Date(today.getTime() + 8 * 24 * 60 * 60 * 1000);
      return this.DateTimeToString(maxDate);
    },
    minDateTime() {
      var today = new Date();
      return this.DateTimeToString(today);
    },
    DateTimeToString(datetime, zeroTime=true) {
      var year = datetime.getFullYear();
      var month = (datetime.getMonth() + 1).toString().padStart(2, "0");
      var day = datetime.getDate().toString().padStart(2, "0");
      var hour = datetime.getHours().toString().padStart(2, "0");
      var minute = datetime.getMinutes().toString().padStart(2, "0");
      if (zeroTime) {
        hour = "00";
        minute = "00";
      }
      return `${year}-${month}-${day}T${hour}:${minute}`;
    },
  },
  data() {
    return {
      creating: false,
      city: null,
    };
  },
  components: {
    coachSelectDialog,
    VDistpicker,
  },
  props: {
    orderUsages: Array,
    orderId: Number,
    showEditButton: Boolean,
  },
};
</script>

<style>
.order-usage {
  padding: 20px 10px;
  border-bottom: 1px solid #e5e5e5;
}
.usage-input,
.date-input,
.time-input {
  margin-bottom: 5px;
}
.distpicker-address-wrapper select {
  float: left;
}
.distpicker-address-wrapper label:nth-child(1) select {
  display: none;
}
.distpicker-address-wrapper label:nth-child(2) select {
  margin-right: 10px;
}

.hour-radio, .course-focus-radio {
  padding: 5px 8px;
  border-radius: .25rem;
  display: inline;
  border: 1px solid #d2d2d2;
  margin-right: 3px;
}

.hour-radio.active, .course-focus-radio.active {
  background: #0cc160;
  color: white;
  border: 1px solid #7fd39a;
}

.distpicker-address-wrapper select {
  width: 110px;
}
</style>
