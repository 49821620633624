<template>
  <div>
    <div class="userInfo card">
      <div class="git-version-sha">Version a98e8f2</div>
      <img v-bind:src="headimg" alt="" width="100">
      <p>
        <span
          v-if="isCoach==true"
          style="background: #7de37c;padding: 5px;border-radius: 10px;font-size: 0.9em;color: #2a6228;margin-right: 0px;">
          教练</span>
        {{ username }}
      </p>
    </div>
    <div class="page card">
      <div class="page__bd page__bd_spacing">
        <div class="weui-flex">
          <div class="weui-flex__item" style="background-color: white; border-right: 1px solid rgb(238, 238, 238);">
            <a href="/order" class="weui-media-box weui-media-box_appmsg">
              <div class="weui-media-box__hd">
                <img class="weui-media-box__thumb" src="../assets/orders_active.png" alt="">
              </div>
              <div class="weui-media-box__bd">
                <h4 class="weui-media-box__title">我的订单</h4>
                <p class="weui-media-box__desc">点击查看</p>
              </div>
            </a>
          </div>
          <div class="weui-flex__item" style="background-color: white; border-right: 1px solid rgb(238, 238, 238);">
            <a v-if="isCoach==false" href="#" class="weui-media-box weui-media-box_appmsg">
              <div class="weui-media-box__hd">
                <img class="weui-media-box__thumb" src="../assets/youhuiquan.png" alt="">
              </div>
              <div class="weui-media-box__bd">
                <h4 class="weui-media-box__title">优惠券</h4>
                <p class="weui-media-box__desc">可用：{{ couponCount }} 张</p>
              </div>
            </a>
            <a v-else href="/coach_salary" class="weui-media-box weui-media-box_appmsg">
              <div class="weui-media-box__hd">
                <img class="weui-media-box__thumb" src="../assets/youhuiquan.png" alt="">
              </div>
              <div class="weui-media-box__bd">
                <h4 class="weui-media-box__title">我的收益</h4>
                <p class="weui-media-box__desc">
                  点击查看
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="weui-panel__bd card">
      <div class="weui-media-box weui-media-box_small-appmsg small-menu">
        <div class="weui-cells">
          <div class="weui-cell  weui-cell_select" style="padding: 0 16px">
            <div class="weui-cell__hd">
              <!-- <img src="../assets/help.png" style="width:20px;margin-right:5px;display:block"> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="icon bi bi-geo-alt-fill" viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
              </svg>
            </div>
            <div class="weui-cell__hd">
              <label class="weui-label">所在城市</label>
            </div>
            <div class="weui-cell__bd">
                <select
                  class="weui-select course-focus" name="select1"
                  style="text-align: right; text-align-last: right;padding-right:23px;color:gray"
                  v-model="userCity"
                >
                  <option value="南京市">南京市</option>
                  <option value="武汉市">武汉市</option>
                </select>
            </div>
          </div>
          <a class="weui-cell weui-cell_access" href="/bind_phone">
            <div class="weui-cell__hd">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone-fill" viewBox="0 0 16 16">
                <path d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z"/>
              </svg>
            </div>
            <div class="weui-cell__bd weui-cell_primary">
              <p>绑定手机<span style="float:right;color:gray">{{ userPhone }}</span></p>
            </div>
            <span class="weui-cell__ft"></span>
          </a>
          <a class="weui-cell weui-cell_access" href="tel:18082148444">
            <div class="weui-cell__hd">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
              </svg>
            </div>
            <div class="weui-cell__bd weui-cell_primary">
              <p>联系我们<span style="float:right;color:gray">18082148444</span></p>
            </div>
            <span class="weui-cell__ft"></span>
          </a>
          <a class="weui-cell weui-cell_access" href="https://mp.weixin.qq.com/s/MhIqEK-LBrtbmRdRGJ3_-g">
            <div class="weui-cell__hd">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book-fill" viewBox="0 0 16 16">
                <path d="M8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
              </svg>
            </div>
            <div class="weui-cell__bd weui-cell_primary">
              <p>陪驾协议</p>
            </div>
            <span class="weui-cell__ft"></span>
          </a>
          <a class="weui-cell weui-cell_access" href="https://work.weixin.qq.com/kfid/kfcb26ba7b48a427524?FromMpMsg=1&MpAppId=wxe740c80102759ea7">
            <div class="weui-cell__hd">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-headset" viewBox="0 0 16 16">
                <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"/>
              </svg>
            </div>
            <div class="weui-cell__bd weui-cell_primary">
              <p>在线客服</p>
            </div>
            <span class="weui-cell__ft"></span>
          </a>
          <a class="weui-cell weui-cell_access" href="/articles">
            <div class="weui-cell__hd">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z"/>
              </svg>
            </div>
            <div class="weui-cell__bd weui-cell_primary">
              <p>帮助中心</p>
            </div>
            <span class="weui-cell__ft"></span>
          </a>
        </div>

        <div class="weui-cells" style="margin-top: 15px" v-if="isAdmin">
          <a class="weui-cell weui-cell_access admin-start" href="/create_free_order_usage">
            <div class="weui-cell__hd"><img src="../assets/admin.png" style="width:20px;margin-right:5px;display:block"></div>
            <div class="weui-cell__bd weui-cell_primary">
              <p>创建体验课</p>
            </div>
            <span class="weui-cell__ft"></span>
          </a>
          <a class="weui-cell weui-cell_access" href="/admin/finish_usage">
            <div class="weui-cell__hd"><img src="../assets/admin.png" style="width:20px;margin-right:5px;display:block"></div>
            <div class="weui-cell__bd weui-cell_primary">
              <p>强制完成预约课程</p>
            </div>
            <span class="weui-cell__ft"></span>
          </a>
          <a class="weui-cell weui-cell_access" href="/admin/recorders">
            <div class="weui-cell__hd"><img src="../assets/admin.png" style="width:20px;margin-right:5px;display:block"></div>
            <div class="weui-cell__bd weui-cell_primary">
              <p>查看教学录音</p>
            </div>
            <span class="weui-cell__ft"></span>
          </a>
        </div>
      </div>
    </div>
    <div style="padding: 0 15px; margin-bottom: 15px;">
      <a href="javascript:;" class="weui-btn weui-btn_default" @click="logOut">退出登录</a>
    </div>
  </div>
</template>

<script>
  import { getUserFromLocalStorage, clearUserFromLocalStorage, updateUserCity } from '../js/user.js'

  const functions = [
    {
      "name": "使用帮助",
      "icon": require("../assets/help.png"),
      "href": "#",
    },
    {
      "name": "服务协议",
      "icon": require("../assets/headimg.png"),
      "href": "#",
    },
    {
      "name": "联系我们",
      "icon": require("../assets/phone.png"),
      "href": "#",
    },
    {
      "name": "意见反馈",
      "icon": require("../assets/headimg.png"),
      "href": "#",
    },
  ]

  var username = "加载中……";
  var headimg = "";
  var isCoach = false;
  var isAdmin = false;
  var userPhone = "";
  var userCity = "";

  export default {
    name: "user",
    props: {},
    data: function () {
      return {
        functions,
        couponCount: "-",
        username,
        headimg,
        isCoach,
        isAdmin,
        userPhone,
        userCity,
      }
    },
    watch: {
      userCity: function (newCity) {
        updateUserCity(newCity);
      }
    },
    mounted: async function () {
      var user = await getUserFromLocalStorage();
      this.username = user.name;
      this.headimg = user.headimg;
      this.isCoach = user.isCoach;
      this.isAdmin = user.isAdmin;
      this.userPhone = user.phone;
      this.userCity = user.city;
    },
    methods: {
      logOut: function () {
          if (confirm("确定退出登录？")) {
            clearUserFromLocalStorage();
            this.$cookies.remove("sessionid");
            this.$router.push("/");
          }
      }
    }
  };
</script>

<style scoped>
  .userInfo {
    background-color: #09bb07;
    color: white;
    text-align: center;
    padding: 20px;
    padding-top: 10px;
  }

  .userInfo img {
    border-radius: 50%;
    margin-bottom: 10px;
    width: 100px;
    height: 100px;
    border: 6px solid #7de37c;
  }

  .userInfo p {
    font-size: 1.5em;
  }

  .card {
    margin-bottom: 15px;
  }

  .git-version-sha {
    font-size: small;
    color: #05cd01;
    text-align: left;
  }

  .small-menu svg {
    color: white;
    padding: 5px;
    border-radius: 5px;
    background: #0bbb08;
    margin-right: 10px;
    display: block;
  }
</style>
