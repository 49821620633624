import { request } from "./api";

const ORDERS_API = "/web_api/orders"
const ORDER_USAGE_API = "/web_api/orders/{}/usages"
const STATUS = {
    WaittingCoach: -1,
    Serving: 0,
}


async function getOrders() {
    return await request("get", ORDERS_API, {}, {
        "with_waitting_coach": 1,
    });
}


async function createOrder(goodsId) {
    return await request("post", ORDERS_API, {
        goods_id: goodsId,
    }, {});
}


async function grabOrder(orderId) {
    return await request("patch", `${ORDERS_API}/${orderId}`, {
        "status": STATUS.Serving,
    }, {});
}


async function cancelOrder(orderId) {
    return await request("patch", `${ORDERS_API}/${orderId}`, {
        "status": STATUS.WaittingCoach,
    }, {});
}

async function createUsageRecord(orderId, usageDuration, datetime) {
    return await request(
        "post",
        `${ORDER_USAGE_API.replace("{}", orderId)}`,
        {
            "usage_duration": usageDuration,
            "datetime": datetime, // like "2020-01-01 00:00"
        },
        {},
    )
}

export {
    getOrders,
    createOrder,
    grabOrder,
    cancelOrder,
    createUsageRecord,
}
