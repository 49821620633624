<template>
  <div class="weui-form-preview">
    <div
      role="option"
      aria-labelledby="p4 js_a11y_comma p5 js_a11y_comma p6"
      class="weui-form-preview__bd"
    >
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">课程套餐</label>
        <span class="weui-form-preview__value">{{ goodsName }}</span>
      </div>
      <div class="weui-form-preview__item" v-if="isCoachView">
        <label class="weui-form-preview__label">学员姓名</label>
        <span class="weui-form-preview__value">{{ user_name }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">预约 ID</label>
        <span class="weui-form-preview__value" v-bind:data-id="id">{{ encrypt_id }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">预约时间</label>
        <span class="weui-form-preview__value">{{ datetime }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">预约时长</label>
        <span class="weui-form-preview__value">{{ usage_duration/60 }}小时</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">联系方式</label>
        <span class="weui-form-preview__value">{{ user_contact }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">预约地址</label>
        <span class="weui-form-preview__value">{{ address }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">练习内容</label>
        <span class="weui-form-preview__value">{{ course_focus || "—" }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">特殊要求</label>
        <span class="weui-form-preview__value">{{ special_requirement }}</span>
      </div>
      <div class="weui-form-preview__item" v-if="!isCoachView">
        <label class="weui-form-preview__label">教练</label>
        <span v-if="!coach" class="weui-form-preview__value">待接单...</span>
        <span v-else class="weui-form-preview__value">{{ coach.name }}</span>
      </div>
      <div class="weui-form-preview__item" v-if="designatedCoach">
        <label class="weui-form-preview__label">指定教练</label>
        <!-- TODO：把教练的头像加上？ -->
        <span class="weui-form-preview__value">{{ designatedCoach.name }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">预约状态</label>
        <span v-if="status == -2" class="weui-form-preview__value">
          <span class="dot" style="background-color: red" v-bind:data-status="status"></span>
          教练已拒绝接单
        </span>
        <span v-else-if="status == -1" class="weui-form-preview__value">
          <span class="dot" style="background-color: orange" v-bind:data-status="status"></span>
          正在等待教练接单
        </span>
        <span v-else-if="status == 0" class="weui-form-preview__value">
          <span class="dot" style="background-color: #20eb20" v-bind:data-status="status"></span>
          待服务
        </span>
        <span v-else-if="status == 1" class="weui-form-preview__value">
          <span class="dot" style="background-color: #4e75ff" v-bind:data-status="status"></span>
          已完成
        </span>
        <span v-else class="weui-form-preview__value">
          <span class="dot" style="background-color: red" v-bind:data-status="status"></span>
          未知状态
        </span>
      </div>
      <div class="weui-form-preview__item" v-if="is_designated">
        <label class="weui-form-preview__label">备注</label>
        <span class="weui-form-preview__value" style="color: green">恭喜！您是被用户指定的教练</span>
      </div>
    </div>

    <div v-if="hidden_buttons">
    </div>
    <div v-else-if="isCoachView">
      <div class="weui-form-preview__ft" v-if="is_designated && status == -1">
        <a role="button" class="weui-form-preview__btn weui-form-preview__btn_default" v-on:click="coachTakeUsage">接受</a>
        <a role="button" class="weui-form-preview__btn weui-form-preview__btn_default" v-on:click="coachRefuseUsage">拒绝</a>
      </div>
      <div class="weui-form-preview__ft" v-else-if="status == 0">
        <a role="button" v-bind:href="`/usage_for_coach/${id}/recorder`" class="weui-form-preview__btn weui-form-preview__btn_default">开始服务并录音 🎙️（测试）</a>
      </div>
      <div class="weui-form-preview__ft" v-else-if="status == -1">
        <a role="button" class="weui-form-preview__btn weui-form-preview__btn_default" v-on:click="coachTakeUsage">抢单</a>
      </div>
    </div>
    <div v-else>
      <div class="weui-form-preview__ft" v-if="status == 0">
          <a role="button" class="weui-form-preview__btn weui-form-preview__btn_default" v-on:click="userUntakeUsage">取消此预约</a>
          <a role="button" class="weui-form-preview__btn weui-form-preview__btn_default" v-on:click="finishUsage">此课时已完成</a>
      </div>
      <div class="weui-form-preview__ft" v-else-if="status == -1">
          <a role="button" class="weui-form-preview__btn weui-form-preview__btn_default" v-on:click="deleteUsage">取消此预约</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderUsageItem",
  props: {
    id: Number,
    goodsName: String,
    encrypt_id: String,
    orderId: Number,
    datetime: String,
    user_name: String,
    usage_duration: Number,
    user_contact: String,
    address: String,
    course_focus: String,
    special_requirement: String,
    coach: Object,
    designatedCoach: Object,
    status: Number,
    isCoachView: {
      type: Boolean,
      default: false
    },
    is_designated: {
      type: Boolean,
      default: false
    },
    hidden_buttons: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    coachTakeUsage () {
      this.$emit('coachTakeUsage', this.id);
    },
    userUntakeUsage () {
      this.$emit('userUntakeUsage', this.id);
    },
    finishUsage () {
      this.$emit('finishUsage', this.id);
    },
    deleteUsage () {
      this.$emit('deleteUsage', this.id);
    },
    coachRefuseUsage () {
      this.$emit('coachRefuseUsage', this.id);
    }
  }
};
</script>

<style>
.weui-form-preview {
  margin-bottom: 15px;
}
.weui-form-preview__value {
  color: black;
}
/* 解决 ios 浏览器中的布局错乱 */
.weui-form-preview__label {
  text-align-last: initial;
}
</style>
