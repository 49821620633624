import { request } from "./api";

const PREPAY_API = "/web_api/prepay"


async function createPrePayOrder(goodsId, userId, userPhone) {
    return await request("post", PREPAY_API, {
        goods_id: goodsId,
        user_id: userId,
        user_phone: userPhone,
    }, {});
}

export {
    createPrePayOrder
}
