<template>
<div>
  <div class="page__bd" style="height: 10%; position: absolute; top:0; bottom:0; left:0; right:0">
    <div class="weui-tab">
      <div class="weui-navbar">
        <div class="weui-navbar__item weui-bar__item_on" v-on:click="click1">
          {{ title1 }}
        </div>
        <div class="weui-navbar__item" v-on:click="click2">{{ title2 }}</div>
        <div class="weui-navbar__item" v-on:click="click3">{{ title3 }}</div>
      </div>
    </div>
  </div>
  <div class="page__bd" style="height: 100%; opacity:0">
    <div class="weui-tab">
      <div class="weui-navbar">
        <div class="weui-navbar__item weui-bar__item_on">padding</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import $ from "jquery";

export default {
  name: "navbar",
  methods: {
      click1: function () {
          this.$emit('click1');
      },
      click2: function () {
        this.$emit('click2');
      },
      click3: function () {
        this.$emit('click3');
      }
  },
  props: {
    title1: String,
    title2: String,
    title3: String,
  },
};

$(function(){
    $(document).on('click', '.weui-navbar__item', function () {
        $(this).addClass('weui-bar__item_on');
        $(this).siblings('.weui-bar__item_on').removeClass('weui-bar__item_on');
    });
});
</script>

<style></style>
