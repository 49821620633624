import axios from "axios";

const DEBUG = window.location.href.indexOf("localhost") != -1 || window.location.href.indexOf("127.0.0.1") != -1;
var DOMAIN = "https://test.xiabandaojishi.com"
if (DEBUG) {
    DOMAIN = "http://127.0.0.1:8000"
}
const NEED_AUTH_CODE = 403


async function request (method, path, data={}, params={}) {
    try {
        var response = await axios({
            method: method,
            url: `${DOMAIN}${path}`,
            data: data,
            params: params,
            withCredentials: true,
        });
        console.log("response", response);
        var status_code = response.data.code;
        if (status_code == NEED_AUTH_CODE) {
            var currentURL = window.location.href;
            window.location.href = "/login?service=" + currentURL;
        }
        else if (status_code != 200) {
            var error_message = `${response.data.message} (错误码${status_code})`;
            throw new Error(error_message);
        }
        return response.data.data;
    } catch (error) {
        alert(error)
        throw new Error(error);
    }
}


export {
    request,
    DOMAIN,
}
