<template>
  <div class="">
    <div class="weui-cells weui-cells_form">
      <div class="weui-cell">
        <div class="weui-cell__hd"><label class="weui-label">学员*</label></div>
        <div class="weui-cell__bd">
          <select class="user-select" style="border: 0;font-size: inherit;height: 24px;width: 100%">
            <option value="">请选择学员</option>
            <template v-for="user in users">
              <option v-bind:value="user.id" v-bind:key="user.id">{{ user.name }}</option>
            </template>
          </select>
        </div>
      </div>
      <div class="weui-cell">
        <div class="weui-cell__bd">
            <input
              id="phone-input" class="weui-input"
              type="number" pattern="[0-9]*" @input="userPhoneChanged"
              placeholder="或者在此输入电话号码"
            />
        </div>
      </div>
    </div>

    <div class="weui-cells weui-cells_form">
      <div class="weui-cell">
        <div class="weui-cell__hd"><label class="weui-label">联系方式*</label></div>
        <div class="weui-cell__bd"><input class="weui-input contact-input" type="string" placeholder="请输入电话、微信或其他"/></div>
      </div>
      <div class="weui-cell">
        <div class="weui-cell__hd"><label for="" class="weui-label">开始时间*</label></div>
        <div class="weui-cell__bd"><input class="weui-input datetime-input" type="datetime-local" value="" placeholder=""/></div>
      </div>
      <div class="weui-cell">
        <div class="weui-cell__hd"><label class="weui-label">预约时长*</label></div>
        <div class="weui-cell__bd"><input class="weui-input duration-input" disabled type="number" value="2"/></div>
      </div>
      <div class="weui-cell">
        <div class="weui-cell__hd"><label class="weui-label">详细地址*</label></div>
        <div class="weui-cell__bd"><input class="weui-input address-input" @change="parseCity()" type="string" placeholder="请输入详细地址"/></div>
      </div>
      <div style="padding: 0px 16px 16px 16px; color: gray">
        <small>根据地址自动解析城市：{{ this.city }}</small>
      </div>
    </div>

    <div class="weui-cells weui-cells_form">
      <div class="weui-cell">
        <div class="weui-cell__hd"><label class="weui-label">指定教练</label></div>
        <div class="weui-cell__bd" v-on:click="showUsageRecords">
          <input class="weui-input display-coach-input" disabled placeholder="指定一个教练，非必填"/>
          <input hidden class="weui-input coach-input"/>
        </div>
      </div>
    </div>

    <coach-select-dialog
      v-on:coachSelected="onCoachSelected"
    ></coach-select-dialog>

    <div class="weui-cells weui-cells_form">
      <div class="weui-cells weui-cells_form" style="margin-top:0">
          <div class="weui-cell">
            <div class="weui-cell__bd">
                <textarea class="weui-textarea special_requirement" placeholder="备注你的特殊要求。比如：我只接受女教练" rows="3"></textarea>
                <div class="weui-textarea-counter"><span>0</span>/200</div>
            </div>
          </div>
      </div>
    </div>

    <div class="weui-btn-area">
        <a class="weui-btn weui-btn_primary" v-on:click="createUsage">确定</a>
      </div>
  </div>
</template>

<script>
import $ from "jquery";
import { createFreeOrderUsage } from "../js/order_usage";
import { getUsers } from "../js/user";
import { showDialog } from "../js/order_usage_records_dialog";
import coachSelectDialog from "../components/coach_select_dialog.vue";

export default {
  name: "orderUsageAdd",
  async mounted () {
    this.users = await getUsers();

    setInterval(() => {
      this.parseCity();
    }, 500);
  },
  methods: {
    async createUsage() {
      if (this.creating) {
        return;
      }

      this.getFormData(); // Just for validating params
      if (confirm("确定创建新的预约？")) {
        this.creating = true;
        $(".weui-btn-area a").text("正在创建...");
        try {
          await createFreeOrderUsage(this.getFormData());
          this.$router.push(`/usage_for_coach`);
        } finally {
          this.creating = false;
          $(".weui-btn-area a").text("确定");
        }
      }
    },
    showUsageRecords () {
      console.log("showUsageRecords clicked");
      showDialog();
    },
    getFormData() {
      var user_id = $(".user-select").val();
      var user_phone = $("#phone-input").val();
      var user_contact = $(".contact-input").val();
      var datetime = $(".datetime-input").val();
      var usage_duration = $(".duration-input").val();
      var address = $(".address-input").val();
      var designated_coach_id = $(".coach-input").val();
      var special_requirement = $(".special_requirement").val();

      if (!user_id && !user_phone) {
        alert("请选择学员");
        throw new Error;
      }
      if (user_phone && user_phone.length != 11) {
        alert("电话号码格式错误");
        throw new Error;
      }
      if (!user_contact) {
        alert("联系方式 不能为空");
        throw new Error;
      }
      if (!datetime) {
        alert("开始时间 不能为空");
        throw new Error;
        
      }
      if (!usage_duration) {
        alert("预约时长 不能为空");
        throw new Error;
        
      }
      if (!address) {
        alert("详细地址 不能为空");
        throw new Error;
        
      }
      usage_duration = parseInt(usage_duration) * 60;
      if (usage_duration % 1 != 0) {
        alert("预约时长 必须是整数");
        throw new Error;
      }

      return {
        user_id,
        user_phone,
        user_contact,
        datetime,
        usage_duration,
        address,
        designated_coach_id,
        special_requirement,
      };
    },
    onCoachSelected(coachId, coachName, coachHeadimg) {
      console.log("selsect coachId", coachId, coachName, coachHeadimg);
      $(".display-coach-input").val(coachName);
      $(".coach-input").val(coachId);
    },
    userPhoneChanged() {
      var phone = $("#phone-input").val();
      $(".contact-input").val(phone);
    },
    parseCity() {
      var address = $(".address-input").val();

      if (!address) {
        this.city = "等待填写地址";
      } else
      if (address.includes("武汉")) {
        this.city = "武汉市";
      } else {
        this.city = "南京市";
      }
    },
  },
  data() {
    return {
      users: [],
      creating: false,
      city: "等待填写地址",
    };
  },
  components: {
    coachSelectDialog,
  },
  props: {
    orderUsages: Array,
    showEditButton: Boolean,
  },
};
</script>

<style>
.order-usage {
  padding: 20px 10px;
  border-bottom: 1px solid #e5e5e5;
}
.usage-input,
.date-input,
.time-input {
  margin-bottom: 5px;
}
</style>
