<template>
  <div>
    <div v-if="sources == null" class="weui-loadmore" style="margin-top: 200px; color: gray">
      <div class="loading_demo_inner">
        <span>正在加载 </span>
        <i role="img" aria-label="正在加载" class="weui-loading"></i>
      </div>
    </div>
    <div v-else-if="sources.length == 0" class="weui-loadmore" style="margin-top: 200px; color: gray">
      <div class="loading_demo_inner">
        <span>暂无数据 </span>
      </div>
    </div>
  </div>
</template>

<script>

var sources = null;

export default {
  name: "loadingIcon",
  data () {
    return {
    }
  },
  props: {
    sources,
  },
};
</script>
