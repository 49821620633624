<template>
  <div class="page">
    <navbar
      v-bind:title1="'订单中心 (' + filterOrders(allOrders, [true, false]).length + ')'"
      v-bind:title2="'待服务 (' + filterOrders(allOrders, [false]).length + ')'"
      v-bind:title3="'已完成 (' + filterOrders(allOrders, [true]).length + ')'"
      v-on:click1="showOrders([true, false])"
      v-on:click2="showOrders([false])"
      v-on:click3="showOrders([true])"
      class="order-navbar"
    ></navbar>
    <div v-for="order in orders" v-bind:key="order.id" class="order-item-box">
      <grayDatetime v-bind:datetime="order.created_at"></grayDatetime>
      <orderItem
        v-bind:goodsName="order.goods.name"
        v-bind:goodsDesr="order.goods.description"
        v-bind:goodsDuration="order.goods.course_duration"
        v-bind:lastDuration="order.last_usage"
        v-bind:city="order.goods.city"
        v-bind:carType="order.goods.car_type"
        v-bind:already_finished="order.already_finished"
        v-bind:orderId="order.id"
        v-bind:userName="order.user.name"
        v-bind:usageRecordsCount="order.usage_history.length"
        class="order-item"
      ></orderItem>
    </div>
    
    <loading-icon :sources="orders"></loading-icon>
  </div>
</template>

<script>
import orderItem from "../components/order_item.vue";
import navbar from "../components/navbar.vue";
import grayDatetime from "../components/gray-datetime.vue";
// import { getOrders, grabOrder, cancelOrder } from "../js/order";
import { getOrders, cancelOrder } from "../js/order";
import { getUserFromLocalStorage } from "../js/user";
import { showDialog } from "../js/order_usage_records_dialog";
import loadingIcon from '../components/loading_icon';

var allOrders = [];  // 当前用户三个菜单下所有的 orders
var orders = null;  // 当前菜单下展示的 orders
var focuOrderUsages = [];

export default {
  name: "order",
  props: {},
  components: {
    orderItem,
    navbar,
    grayDatetime,
    loadingIcon,
  },
  data: function () {
    return {
      orders,
      allOrders,
      focuOrderUsages,
      isCoachView: false,
      focuOrderId: null,
    };
  },
  async mounted() {
    this.isCoachView = (await getUserFromLocalStorage()).isCoach;
    if (this.isCoachView) {
      // 教练跳转到专属的订单页面（其实是 usage 页面）
      this.$router.push("/usage_for_coach");
    }
    else {
      // 学员默认显示“所有”的订单
      this.allOrders = await getOrders();
      this.orders = this.filterOrders(this.allOrders, [true, false]);
    }
  },
  methods: {
    showOrders: function (already_finished_statuses) {
      this.orders = this.filterOrders(this.allOrders, already_finished_statuses);
    },
    showUsageRecords (orderId) {
      this.focuOrderUsages = this.allOrders.filter(function (order) {
        return order.id == orderId;
      })[0].usage_history;
      this.focuOrderId = orderId;
      showDialog();
    },
    // async grabOrder (orderId) {
    //   if (confirm("确定抢单？")) {
    //     await grabOrder(orderId);
    //     this.allOrders = await getOrders();
    //     this.orders = this.orders.filter(order => order.id != orderId);
    //   }
    // },
    async cancelOrder (orderId) {
      if (confirm("确定要取消该订单吗？")) {
        await cancelOrder(orderId);
        this.allOrders = await getOrders();
        this.orders = this.orders.filter(order => order.id != orderId);
      }
    },
    filterOrders(orders, already_finished_statuses) {
      return orders.filter(function (order) {
        return already_finished_statuses.includes(order.already_finished);
      });
    },
  }
};
</script>

<style scoped>
@import "../css/dialog.css";

.weui-form-preview {
  margin-bottom: 20px;
}
.weui-form-preview__value {
  color: black;
}
.order-item-box {
  padding: 0 15px;
  margin-bottom: 15px;
  border-radius: 15px;
}
.order-item-box,
.order-item {
  border-radius: 15px;
}
.order-item-box,
.order-item::before,
.order-item-box,
.order-item::after {
  border: 0;
}
.order-navbar {
  margin-bottom: 15px;
}
</style>
