<template>
<div class="page">
    <div id="dialogs">
        <div class="js_dialog" role="dialog"  aria-hidden="true" aria-modal="true" aria-labelledby="js_title1" id="iosDialog1" style="display: none;">
          <div class="weui-mask"></div>
          <div class="weui-dialog">
              <div class="weui-dialog__hd"><strong class="weui-dialog__title" id="js_title1">{{ title }}</strong></div>
              <div class="weui-cells__group weui-cells__group_form weui-cells_checkbox" style="margin-bottom: 16px">
                  <label class="weui-cell weui-cell_active weui-check__label" for="user-agreement">
                      <div class="weui-cell__hd" style="padding-left: 16px; padding-right: 0">
                          <input type="checkbox" name="checkbox1" class="weui-check" id="user-agreement"/>
                          <i class="weui-icon-checked"></i>
                      </div>
                      <div class="weui-cell__bd">
                          <p v-html="content"></p>
                      </div>
                  </label>
              </div>
              <div class="weui-dialog__ft">
                  <a role="button" href="javascript:" v-on:click="cancel" class="weui-dialog__btn weui-dialog__btn_default">{{ cancelText }}</a>
                  <a role="button" href="javascript:" v-on:click="confirm" class="weui-dialog__btn weui-dialog__btn_primary">{{ confirmText }}</a>
              </div>
          </div>
        </div>
    </div>
</div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "WeuiConfirm",
  // 现在只给登录输入框用，所以内容写写死
  props: {
    title: {
      type: String,
      default: "即将使用微信登录"
    },
    content: {
      type: String,
      default: "我已阅读并同意<a href='https://mp.weixin.qq.com/s/MhIqEK-LBrtbmRdRGJ3_-g'>陪练协议</a>。"
    },
    cancelText: {
      type: String,
      default: "取消"
    },
    confirmText: {
      type: String,
      default: "确定"
    }
  },
  mounted: function() {
  },
  methods: {
    show: function() {
      var $iosDialog1 = $('#iosDialog1');
      $iosDialog1.fadeIn(200);
      $iosDialog1.attr('aria-hidden','false');
      $iosDialog1.attr('tabindex','0');
      $iosDialog1.trigger('focus');
    },
    hide: function() {
      var $iosDialog1 = $('#iosDialog1');
      $iosDialog1.fadeOut(200);
      $iosDialog1.attr('aria-hidden','true');
      $iosDialog1.removeAttr('tabindex');
    },
    confirm: function() {
      // user-agreement
      if (!$('#user-agreement').is(':checked')) {
        alert('请先阅读并同意陪练协议');
        return;
      }
      this.hide();
      this.$emit('confirm');
    },
    cancel: function() {
      this.hide();
      this.$emit('cancel');
    }
  }
};
</script>

<style></style>
