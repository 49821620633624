<template>
  <div>
  </div>
</template>

<script>
import WeuiConfirm from "../components/weui_confirm.vue";
import Vue from "vue";
import { getUserInfoByWechatCode } from '../js/user';

export default {
  name: "login",
  props: {},
  components: {},
  data: function () {
    return {
    };
  },
  async mounted() {
    var wechatCode = this.$route.query.code;
    if (wechatCode != undefined) {
      await getUserInfoByWechatCode(wechatCode);
      var service = this.$route.query.service;
      window.location.href = service || "/";
    }
    else {
      this.createLoginDialog().$children[0].show();
    }
  },
  methods: {
    getWechatAuthUrl: function () {
      var APP_ID = "wxe740c80102759ea7";
      var currentURL = window.location.href;
      currentURL = encodeURIComponent(currentURL);
      return "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + APP_ID + "&redirect_uri=" + currentURL + "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redire";
    },
    createLoginDialog: function () {
      var weuiConfirm = new Vue({
        el: "#vue-confirm-dialog",
        render: (h) => h(WeuiConfirm),
      });
      weuiConfirm.$children[0].$on("confirm", () => {
        window.location.href = this.getWechatAuthUrl();
        throw new Error(`need wechat auth`);
      });
      weuiConfirm.$children[0].$on("cancel", () => {
        window.location.href = "/";
      });

      return weuiConfirm;
    }
  },
};
</script>

<style>
</style>
