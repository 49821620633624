// activeUrlname 有两种策略更新
// 1. 页面刷新时，通过 App.vue 传递当前页面的 name 来更新
// 2. 底导被点击时，通过被点击的按钮来更新
<template>
  <div role="tablist" aria-label="选项卡标题" class="weui-tabbar">
    <div
      v-for="tabItem in tabs"
      v-bind:key="tabItem.name + tabItem.isActive"
      class="weui-tabbar__item"
      v-bind:class="{ 'weui-bar__item_on': tabItem.isActive }"
    >
      <router-link
        class="nav-link"
        v-bind:to="tabItem.url"
        @click.native="exchangeActive(tabItem.name)"
      >
        <img
          v-bind:src="tabItem.logo"
          class="weui-tabbar__icon"
          v-if="tabItem.isActive == false"
        />
        <img
          v-bind:src="tabItem.activeLogo"
          class="weui-tabbar__icon"
          v-if="tabItem.isActive == true"
        />
        <p aria-hidden="true" class="weui-tabbar__label">
          {{ tabItem.showName }}
        </p>
      </router-link>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

$(function () {
  $(".weui-tabbar__item").on("click", function () {
    $(this).attr("aria-selected", "true").addClass("weui-bar__item_on");
    $(this)
      .siblings(".weui-bar__item_on")
      .removeClass("weui-bar__item_on")
      .attr("aria-selected", "false");
    var panelId = "#" + $(this).attr("aria-controls");
    $(panelId).css("display", "block");
    $(panelId).siblings(".weui-tab__panel").css("display", "none");
  });
});

var tabs = [
  {
    name: "goods",
    showName: "首页",
    url: "/",
    logo: require("../assets/home_inactive.png"),
    activeLogo: require("../assets/home_active.png"),
    activeRouterNames: ["purcharse", "goods"],
    isActive: false,
  },
  {
    name: "order",
    showName: "订单",
    url: "/order",
    logo: require("../assets/orders_inactive.png"),
    activeLogo: require("../assets/orders_active.png"),
    activeRouterNames: ["order"],
    isActive: false,
  },
  {
    name: "user",
    showName: "我的",
    url: "/user",
    logo: require("../assets/mine_inactive.png"),
    activeLogo: require("../assets/mine_active.png"),
    activeRouterNames: ["user", "article", "articleList"],
    isActive: false,
  },
];
export default {
  name: "tabbar",
  props: {
    activeUrlname: String, // index order user
  },
  data() {
    console.log("activeUrlname", this.activeUrlname);
    for (var index in tabs) {
      if (tabs[index]["name"] === this.activeUrlname || tabs[index]["activeRouterNames"].includes(this.activeUrlname)) {
        tabs[index]["isActive"] = true;
      }
    }
    return { tabs };
  },
  methods: {
    exchangeActive: function (activeTabName) {
      console.log("exchangeActive!");
      for (var index in tabs) {
        if (tabs[index]["name"] === activeTabName) {
          tabs[index]["isActive"] = true;
        } else {
          tabs[index]["isActive"] = false;
        }
      }
    },
  },
};
</script>

<style>
</style>
