import { request } from "./api";

const ARTICLE_LIST_API = `/web_api/articles`

async function getArticleList() {
    return await request("get", ARTICLE_LIST_API, {}, {});
}

async function getArticleOne(articleId) {
    var articles = await request("get", ARTICLE_LIST_API, {}, {
        "article_id": articleId,
        "with_content": 1,
    });
    if (articles.length == 0) {
        return null;
    }
    return articles[0];
}

export {
    getArticleList,
    getArticleOne,
}
