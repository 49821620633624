<template>
  <div class="coach-box weui-cells_radio" v-bind:data-id="id" :class="{'active': hasSelected}" v-on:click="click">
    <img class="" :src="headimg" />
    <div class="text">
      <div class="name">{{ name }}</div>
      <div class="description">{{ description }}</div>
    </div>
    <div class="weui-cell__ft" style="display:none;margin-top:13px">
      <input
        type="radio"
        class="weui-check"
        checked="checked"
      />/>
      <span class="weui-icon-checked"></span>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "coachCard",
  data () {
    return {}
  },
  props: {
    id: Number,
    hasSelected: Boolean,
    name: String,
    headimg: String,
    description: String,
  },
  methods: {
    click () {
      var data_id_selector = `[data-id="${this.id}"]`
      if (this.hasSelected) {
        this.$emit('selected', null, null, null);
        $(`.coach-box${data_id_selector}`).removeClass("active");
        $(`.weui-cell__ft`).hide();
      } else {
        this.$emit('selected', this.id, this.name, this.headimg);
        $(`.coach-box${data_id_selector}`).addClass("active");
        $(`.weui-cell__ft`).hide();
        $(`.coach-box${data_id_selector}>.weui-cell__ft`).show();
      }
    }
  }
};
</script>

<style scoped>
  .coach-box {
    border: 2px solid #f0f0f0;
    padding: 15px;
    border-radius: 15px;
  }
  img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    float: left;
    border-radius: 10px;
  }

  .text {
    text-align: left;
    line-height: 30px;
    font-size: 25px;
    float: left;
  }

  .description {
    font-size: 1em;
    line-height: 20px;
    font-size: 15px;
    color: gray;
  }
  .active {
    border: 2px solid #24d175;
    background: #dbffec;
  }
</style>
