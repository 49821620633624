<template>
  <div class="page">
    <loading-icon :sources="recorders"></loading-icon>
    <div class="recorders-box">
      <div class="recorder-item-box" v-for="recorder in recorders" :key="recorder.id">
        <div class="recorder-item" :id="'usage-'+recorder.usage.id">
          <div class="usage-coach">教练：{{ recorder.usage.coach }}</div>
          <div class="usage-user">学员：{{ recorder.usage.user }}</div>
          <div class="usage-datetime">时间：{{ recorder.usage.datetime }}</div>
          <div class="audio disable" v-if="recorder.status == 'merging'">音频正在处理...</div>
          <div class="audio" v-else v-on:click="showAudio(recorder.usage.id, recorder.file_url)">点击查看录音</div>
          <audio hidden src="" controls="controls"></audio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loadingIcon from '../components/loading_icon';
import { getRecorders } from '../js/admin_recorders'
import $ from 'jquery'

var recorders = null;

export default {
  name: "adminRecorders",
  async mounted () {
    this.recorders = await getRecorders();
  },
  methods: {
    showAudio: function (usageId, recorderFileUrl) {
      console.log(usageId);
      $(`#usage-${usageId} audio`).show();
      $(`#usage-${usageId} .audio`).hide();
      $(`#usage-${usageId} audio`).attr('src', recorderFileUrl);
    }
  },
  data() {
    return {
      recorders,
    };
  },
  components: {
    loadingIcon
  },
};
</script>

<style scoped>
.recorders-box {
  padding: 15px;
}
.recorder-item {
  background: white;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 15px;
}

audio, .audio {
  width: 100%;
  height: 32px;
  margin-top: 10px;
}

.audio {
  background: rgb(241 243 244);;
  border-radius: 10px;
  color: black;
  text-align: center;
  line-height: 32px;
}
.audio.disable {
  color: #a0a0a0;
}
</style>
