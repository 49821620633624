<template>
  <div id="articles-box">
    <div v-for="title in articles" :key="title">
      <a class="weui-cell weui-cell_access article" :href="'/pdf/articles/' + title.replaceAll('/', '_') + '.pdf'">
        <div class="weui-cell__bd weui-cell_primary">
          <p>{{ title }}</p>
        </div>
        <span class="weui-cell__ft"></span>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: "user",
    data: function () {
      return {
        articles: [
          '怎么支付/可以付款给教练吗/如何介绍亲友陪练/如何预约',
          '在什么地方练车/如何安排学车间隔',
          '怎么购买陪练服务和预约使用说明',
          '教练会推销课程吗/我对第一次教练不是很满意还怎么买？',
          '我想给你们介绍客户，有没有优惠？可不可以给教练介绍学员？',
          '提交订单后教练什么时候才接单联系我',
          '三种掉头理论支持',
          '会车让车理论支持',
          '三种变道理论支持',
          '入库停车理论支持',
          '侧方停车理论支持',
          '对教练不满意可以自助更换也可以联系我们',
        ]
      }
    },
  };
</script>

<style scoped>
#articles-box {
  background: white;
}
.article {
  border-bottom: 1px solid #f2f2f2;
}
</style>