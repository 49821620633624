import $ from 'jquery'

function showDialog() {
  const $dialog3 = $('#js_dialog_3');
  const $dialogWrap3 = $('#dialogWrap3');
  $dialogWrap3.fadeIn(200);
  $dialog3.addClass('weui-half-screen-dialog_show');
  setTimeout(function(){
      $dialogWrap3.attr('aria-hidden','false');
      $dialogWrap3.attr('aria-modal','true');
      $dialogWrap3.attr('tabindex','0');
      $dialogWrap3.trigger('focus');
  },200)
}

export {
  showDialog
}
