import { request } from "./api";

const COACH_SALARY_API = "/web_api/coaches/salary"
const COACH_SALARY_SUMMARY_API = "/web_api/coaches/salary_summary"
const COACH_WITHDRAWN_SALARY_API = "/web_api/coaches/withdrawn"

async function getSalary(coachId, year, month) {
    return await request("get", COACH_SALARY_API, {}, {
        coach_id: coachId,
        year,
        month
    });
}

async function getSalarySummary() {
    return await request("get", COACH_SALARY_SUMMARY_API, {}, {});
}

async function withdrawnSalary(year, month, first_half_month) {
    return await request("post", COACH_WITHDRAWN_SALARY_API, {
        year,
        month,
        first_half_month
    }, {});
}

export {
  getSalary,
  getSalarySummary,
  withdrawnSalary
}
