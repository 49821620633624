<template>
  <div class="page">
    <div
      id="showPicker"
      role="button"
      aria-haspopup="listbox"
      class="weui-cell weui-cell_active weui-cell_select weui-cell_select-after"
    >
      <div class="weui-cell__hd">
        <label class="weui-label">{{ selectTitle }}</label>
      </div>
      <div class="weui-cell__bd">
        <div class="weui-select" @click="showPicker">{{ weuiSelectValue }}</div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import weui from "weui.js";

export default {
  name: "weuiSelect",
  props: {
    selectTitle: {
      type: String,
      default: "default",
    },
    selectOptions: {
      type: Array,
      default: () => [
        {
          label: "default",
          value: 0,
        },
      ],
    },
  },
  watch: {
    // 监听到父组件传递过来的数据后，加工一下，
    // 存到data中去，然后在页面上使用
    selectOptions(newnew, oldold) {
      console.log("监听", newnew, oldold);
      this.weuiSelectValue = newnew[0].label;
    },
  },
  data() {
    return {
      weuiSelectValue: this.selectOptions[0]["label"],
    };
  },
  methods: {
    showPicker: function () {
      var that = this;
      weui.picker(this.selectOptions, {
        onConfirm: function (result) {
          that.weuiSelectValue = result[0]["label"];
          that.$emit("selector-changed", that.weuiSelectValue)
        },
        title: this.selectTitle,
      });
    },
  },
};
</script>

<style>
.weui-btn_icon {
  display: none;
}
.weui-select {
  text-align: center;
}
</style>