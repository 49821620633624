<template>
  <div id="salarys-box">
    <h2 style="margin-bottom: 15px" v-if="title">{{ title}} </h2>

    <loading-icon :sources="salarys"></loading-icon>

    <div class="salary-box" v-for="salary in salarys" :key="salary.year*salary.month+salary.first_half_month">
      <div class="salary-title">
        <h2 class="salary-date">{{ salary.date_title }}</h2>
        <h3 class="unit_price_per_hour-box">总收益 {{ salary.total_salary }} 元，课时费 {{ salary.unit_price_per_hour }} 元</h3>
        <div v-if="salary.can_be_withdrawn && !displayOtherCoach">
          <button
            v-if="!has_withdrawn(salary.year, salary.month, salary.first_half_month)"
            class="withdrawn-btn weui-btn weui-btn_primary"
            v-on:click="withdrawn"
            v-bind:year="salary.year"
            v-bind:month="salary.month"
            v-bind:first_half_month="salary.first_half_month"
          >提取</button>
          <button
            v-else
            class="withdrawn-btn weui-btn weui-btn_default"
          >已向管理员申请提取收益</button>
        </div>
      </div>
      <div class="free_goods_usages-box">
        <b>体验课（{{ salary.free_goods_usages.length }}）转化率：{{ Math.round(salary.conversion_rate * 100) }}%</b>
        <table>
          <tbody>
            <tr v-for="usage in salary.free_goods_usages" :key="usage.datetime">
              <td>{{ usage.user_name }}</td>
              <td>{{ usage.datetime }}</td>
              <td v-if="usage.nomal_order_hours.reduce((partialSum, a) => partialSum + a, 0) >= 8">
                已转化<template v-if="isAdmin">（{{ usage.nomal_order_hours.reduce((partialSum, a) => partialSum + a, 0) }}小时）</template>
              </td>
              <td v-else-if="usage.status==0" style="color:#20eb20">
                待服务
              </td>
              <td v-else>
                未转化
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="nomal_goods_usages-box">
        <b>正式课（{{ salary.nomal_goods_usages.length }}）</b>
        <table>
          <tbody>
            <tr v-for="usage in salary.nomal_goods_usages" :key="usage.datetime">
              <td>{{ usage.user_name }}</td>
              <td>{{ usage.usage_duration }}小时</td>
              <td>{{ usage.datetime }}</td>
              <td v-if="usage.status == 0"  style="color:#20eb20">待服务</td>
              <td v-else>已完成</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="salarys != null" class="salary-box load-next-month-btn" v-on:click="loadNextMonthSalary">{{ loadNextMonthSalaryBtnText }}</div>
  </div>
</template>

<script>
import { getSalary, withdrawnSalary } from '../js/coach_salary';
import loadingIcon from '../components/loading_icon';
import { getUserFromLocalStorage } from '../js/user.js'

var salarys = null;
var isAdmin = false;
var actualDisplayCoachId = null;
var displayOtherCoach = false;
var title = null;

export default {
  name: "coachSalary",
  data: function () {
    return {
      salarys,
      isAdmin,
      actualDisplayCoachId,
      displayOtherCoach,
      title,
      loadNextMonthSalaryBtnText: "加载上个月的数据",
    }
  },
  async mounted() {
    var user = await getUserFromLocalStorage();
    this.isAdmin = user.isAdmin;

    if (this.isAdmin) {
      this.actualDisplayCoachId = this.$route.query.coachId || user.id;
      this.actualDisplayCoachId = parseInt(this.actualDisplayCoachId);
      this.displayOtherCoach = this.actualDisplayCoachId != user.id;
      this.title = this.$route.query.title
    } else {
      this.actualDisplayCoachId = user.id;
    }
    this.salarys = await this.getNextMonthSalary();
  },
  methods: {
    withdrawn: async function (event) {
      event.target.setAttribute("disabled", "disabled");
      event.target.innerText = "正在请求……";
      var year = parseInt(event.target.getAttribute("year"));
      var month = parseInt(event.target.getAttribute("month"));
      var first_half_month = event.target.getAttribute("first_half_month") == "true";
      try {
        await withdrawnSalary(year, month, first_half_month);
        localStorage.setItem(this.withdrawnCacheKey(year, month, first_half_month), "true");
        window.location.reload();
      } catch (e) {
        event.target.removeAttribute("disabled");
        event.target.innerText = "提取";
      }
    },
    withdrawnCacheKey: function (year, month, first_half_month) {
      return "withdrawn_" + year + "_" + month + "_" + first_half_month;
    },
    has_withdrawn: function (year, month, first_half_month) {
      return localStorage.getItem(this.withdrawnCacheKey(year, month, first_half_month)) == "true";
    },
    getNextMonthSalary: async function () {
      var year = undefined;
      var month = undefined;

      if (this.salarys == null) {
        year = new Date().getFullYear();
        month = new Date().getMonth() + 1;
      }
      else {
        var lastSalary = this.salarys[this.salarys.length - 1];
        year = lastSalary.year;
        month = lastSalary.month - 1;

        if (month == 0) {
          month = 12;
          year -= 1;
        }
      }

      var oldSalarys = this.salarys || [];
      var newSalarys = await getSalary(this.actualDisplayCoachId, year, month);

      return oldSalarys.concat(newSalarys);
    },
    loadNextMonthSalary: async function () {
      var loadingText = "正在加载……";
      if (this.loadNextMonthSalaryBtnText == loadingText) {
        return;
      }

      this.loadNextMonthSalaryBtnText = loadingText;
      this.salarys = await this.getNextMonthSalary();
      this.loadNextMonthSalaryBtnText = "加载上个月的数据";
    }
  },
  components: {
    loadingIcon
  }
};
</script>

<style scoped>
#salarys-box {
  padding: 15px;
}
.salary-box {
  padding: 15px 15px 5px 15px;
  background: white;
  margin-bottom: 15px;

  border-radius: 10px;
}
.salary-title {
  border-left: 7px solid #045f04;
  padding-left: 10px;
  margin-bottom: 18px
}
.salary-date {
  margin-left: 5px;
}
.unit_price_per_hour-box {
  display: none;
  margin-left: 5px;
  color: #0e6d0e;
}

.free_goods_usages-box, .nomal_goods_usages-box {
    border-radius: 10px;
    margin-bottom: 15px;
}

tr {
  /* line-height: 30px; */
  font-size: .9em;
}

table {
  width:100%;
}

.withdrawn-btn {
  margin-left: 5px;
  width: initial;
}
.load-next-month-btn {
  text-align: center;
  cursor: pointer;
  padding: 10px;
}
</style>