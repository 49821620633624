import { request } from "./api";

const COACH_API = `/web_api/coaches`

async function getCoaches() {
    return await request("get", COACH_API, {}, {});
}

export {
    getCoaches
}
