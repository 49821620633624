<template>
  <div class="page">
    <img
      src="http://m.epeijia.com/cssimages/xy/images/index-new01.jpg"
      alt=""
      width="100%"
      min-width="414px"
    />
    <weuiSelect
      class="city-type-select"
      selectTitle="城市选择"
      v-bind:selectOptions="citySelectOptions"
      v-on:selector-changed="citySelectChanged"
    ></weuiSelect>
    <weuiSelect
      v-if="city=='南京市'"
      class="car-type-select"
      selectTitle="车型选择"
      v-bind:selectOptions="carSelectOptions"
      v-on:selector-changed="carSelectChanged"
    ></weuiSelect>
    <div class="margin-bottom-15px"></div>
    <div class="goods-item" v-for="goods in displayedGoods" v-bind:key="goods.id">
      <goodsItem
        v-bind:actualPrice="goods.actual_price"
        v-bind:originPrice="goods.origin_price"
        v-bind:title="goods.name"
        v-bind:desr="goods.description"
        v-bind:goodsId="goods.id"
        expireDesr="有效期：购买日期起 500 天"
      ></goodsItem>
    </div>

    <loading-icon :sources="displayedGoods"></loading-icon>
  </div>
</template>

<script>
import goodsItem from "../components/goods_item.vue";
import weuiSelect from "../components/weui-select.vue";
import { getGoodsList, getCarAndCitySelectOptions } from "../js/goods";
import loadingIcon from '../components/loading_icon';
import { updateUserCity, getUserFromLocalStorage } from "../js/user";

var carSelectOptions = [
  {
    label: "loading...",
    value: "default",
  },
];
var citySelectOptions = [
  {
    label: "loading...",
    value: "default",
  },
];
var allGoods = null;
var displayedGoods = null;

export default {
  name: "goods",
  components: {
    goodsItem,
    weuiSelect,
    loadingIcon,
  },
  props: {},
  data() {
    return {
      carSelectOptions,
      citySelectOptions,
      allGoods,
      displayedGoods,
      carType: carSelectOptions[0]["label"],
      city: citySelectOptions[0]["label"],
    };
  },
  async mounted() {
    // this.$cookies.set("sessionid", "z103fb683f666f699b0", "2023-01-01");
    var options = await getCarAndCitySelectOptions();
    var citySelectOptions = options.citySelectOptions;
    this.carSelectOptions = options.carSelectOptions;
    this.carType = this.carSelectOptions[0]["label"];
    this.allGoods = await getGoodsList(null, null, this.$route.query.withTestGoods);

    // NOTE(luzhiyuan) 把用户的城市设置为 carSelectOptions 的第一位
    if (this.hasLogin()) {
      var user = await getUserFromLocalStorage();
      var userCity = user.city;
      var userCityIndex = citySelectOptions.findIndex((city) => {
        return city.label == userCity;
      });
      if (userCityIndex != -1) {
        var userCityOption = citySelectOptions.splice(userCityIndex, 1);
        citySelectOptions.unshift(userCityOption[0]);
      }
    }
    this.citySelectOptions = citySelectOptions;
    this.city = await this.defaultCity();
    this.carType = this.defaultCarTypeOnCity(this.city);

    this.refreshGoodsList();
  },
  methods: {
    refreshGoodsList() {
      console.log(`this.allGoods=${this.allGoods.length}, this.carType=${this.carType}, this.city=${this.city}`);
      this.displayedGoods = null;
      setTimeout(() => {
        this.displayedGoods = this.allGoods.filter((goods) => {
          return goods.car_type === this.carType && goods.city === this.city;
        });

        if (this.$route.query.withTestGoods) {
          var testGoodsType = 2;
          var testGoods = this.allGoods.filter((goods) => {
            return goods.type == testGoodsType;
          });
          this.displayedGoods = this.displayedGoods.concat(testGoods);
        }
      }, 200);
    },
    carSelectChanged(carType) {
      this.carType = carType;
      this.refreshGoodsList();
    },
    async citySelectChanged(city) {
      this.city = city;
      this.carType = this.defaultCarTypeOnCity(city);
      this.refreshGoodsList();

      var allowUpdateCitys = ["南京市", "武汉市"];
      if (this.hasLogin() && allowUpdateCitys.includes(city)) {
        var user = await getUserFromLocalStorage();
        if (user.isCoach) return;
        updateUserCity(city);
      }
    },
    hasLogin() {
      console.log("hasLogin", this.$cookies.get("sessionid") != undefined);
      return this.$cookies.get("sessionid") != undefined;
    },
    async defaultCity() {
      if (!this.hasLogin()) return this.citySelectOptions[0]["label"];

      // NOTE(luzhiyuan) 不必区分教练和学员，他们的 city 属性都需要生效
      var user = await getUserFromLocalStorage();
      return user.city;
    },
    defaultCarTypeOnCity(city) {
      if (city == "南京市") {
        return "普通轿车车型";
      }
      if (city == "武汉市") {
        return "不区分车型";
      }
      return "普通轿车车型";
    }
  },
};
</script>

<style scoped>
.city-type-select,
.car-type-select {
  border-bottom: 1px solid rgb(214 214 214);
}
.margin-bottom-15px {
  margin-bottom: 15px;
}
.goods-item {
  margin-bottom: 15px;
  padding: 0 15px;
}
</style>
