import { request } from "./api";

const USER_API = `/web_api/user`
const USERS_API = `/web_api/users`
const WECHAT_LOGIN_API = `/web_api/wechat_login`
const SEND_SMS_CODE = `/web_api/send_sms_code`
const VERIFY_SMS_CODE = `/web_api/verify_sms_code`

async function getUserFromLocalStorage() {
    var userInLocalStorage = JSON.parse(localStorage.getItem("user") || '{}')
    var needResetLocalStorageUser = userInLocalStorage.name == undefined;
    if (needResetLocalStorageUser) {
        var user = await request("get", USER_API, {}, {});

        // NOTE(luzhiyuan) 如果没有绑定 phone，跳转到 bind_phone 页面
        // 为什么不直接判断 user.phone 呢？因为 user 可能为 {}，这时不应该跳到 bind_phone
        if (user.name && !user.phone) {
            // 跳转到 bind_phone 页面，除非现在已经是了
            if (window.location.pathname != "/bind_phone") {
                var service = window.location.href;
                window.location.href = `/bind_phone?service=${service}`;
            }
            return;
        }

        localStorage.setItem("user", JSON.stringify({
            id: user.id,
            name: user.name,
            phone: user.phone,
            city: user.city,
            headimg: user.headimg,
            isCoach: user.is_coach,
            isAdmin: user.is_admin,
        }));
    }
    return JSON.parse(localStorage.getItem("user"));
}

async function getUserInfoByWechatCode(code) {
    var response = await request("post", WECHAT_LOGIN_API, {
        code: code,
    }, {});
    return response;
}

function clearUserFromLocalStorage() {
    localStorage.removeItem("user");
}

async function getUsers() {
    return await request("get", USERS_API, {}, {});
}

async function updateUserPhone(phone) {
    clearUserFromLocalStorage();

    return await request("patch", USER_API, {
        phone: phone,
    }, {});
}

async function updateUserCity(city) {
    clearUserFromLocalStorage();

    return await request("patch", USER_API, {
        city: city,
    }, {});
}

async function sendSmsCode(phone) {
    return await request("post", SEND_SMS_CODE, {
        phone: phone,
    }, {});
}

async function verifySmsCode(phone, code) {
    return await request("post", VERIFY_SMS_CODE, {
        phone: phone,
        code: code,
    }, {});
}

export {
    getUserFromLocalStorage,
    clearUserFromLocalStorage,
    getUserInfoByWechatCode,
    getUsers,
    updateUserPhone,
    updateUserCity,
    sendSmsCode,
    verifySmsCode,
}
