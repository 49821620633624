<template>
  <div class="weui-form-preview">
    <div role="option" class="weui-form-preview__hd">
      <label class="weui-form-preview__label">套餐名称</label>
      <em class="weui-form-preview__value" style="font-size:1.2em">{{ goodsName }}</em>
    </div>
    <div
      role="option"
      aria-labelledby="p4 js_a11y_comma p5 js_a11y_comma p6"
      class="weui-form-preview__bd"
    >
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">学员</label>
        <span class="weui-form-preview__value">{{ userName }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">所在城市</label>
        <span class="weui-form-preview__value">{{ city }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">套餐描述</label>
        <span class="weui-form-preview__value">{{ goodsDesr }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">汽车类型</label>
        <span class="weui-form-preview__value">{{ carType }}</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">套餐总时长</label>
        <span class="weui-form-preview__value">{{ goodsDuration/60 }}小时</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">剩余时长</label>
        <span class="weui-form-preview__value">{{ lastDuration/60 }}小时</span>
      </div>
      <div class="weui-form-preview__item">
        <label class="weui-form-preview__label">订单状态</label>
        <span v-if="already_finished" class="weui-form-preview__value">
          <span class="dot" style="background-color: #4e75ff"></span>
          已完成
        </span>
        <span v-else class="weui-form-preview__value">
          <span class="dot" style="background-color: #20eb20"></span>
          待服务
        </span>
      </div>
    </div>
    <div class="weui-form-preview__ft" v-if="already_finished">
        <a role="button" class="weui-form-preview__btn weui-form-preview__btn_default" v-bind:href="'/order/'+orderId+'/usage'">预约历史 ({{ usageRecordsCount }})</a>
        <!-- <a role="button" class="weui-form-preview__btn weui-form-preview__btn_primary" href="javascript:">去评价</a> -->
    </div>
    <div class="weui-form-preview__ft" v-else>
        <a role="button" class="weui-form-preview__btn weui-form-preview__btn_default" v-bind:href="'/order/'+orderId+'/usage'">使用记录 ({{ usageRecordsCount }})</a>
        <a role="button" class="weui-form-preview__btn weui-form-preview__btn_primary" v-bind:href="'/order/'+orderId+'/usage_add'">预约</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderItem",
  props: {
    goodsName: String,
    goodsDesr: String,
    goodsDuration: Number,
    usageDuration: Number,
    lastDuration: Number,
    usageStartTime: String,
    city: String,
    carType: String,
    already_finished: Boolean,
    orderId: Number,
    userName: String,
    usageRecordsCount: Number,
  },
  data() {
    return {};
  },
};
</script>

<style>
.weui-form-preview {
  margin-bottom: 15px;
}
.weui-form-preview__value {
  color: black;
}
/* 解决 ios 浏览器中的布局错乱 */
.weui-form-preview__label {
  text-align-last: initial;
}
</style>
