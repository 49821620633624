import { request } from "./api";

const RECORDERS = "/web_api/admin/recorders"

async function getRecorders() {
    var response = await request("get", RECORDERS, {}, {});
    return response;
}

export {
    getRecorders,
}
