function onBridgeReady(payload, callback_function) {
  window.WeixinJSBridge.invoke('getBrandWCPayRequest',
  payload,
  function(res) {
      console.log("pay res=", res);
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        callback_function();
      }
  });
}

function wechatPay(payload, callback_function) {
  /*
    payload: {
      "appId": "xxx",
      "timeStamp": "xxx",
      "nonceStr": "xxx",
      "package": "prepay_id=xxx",
      "signType": "xxx",
      "paySign": "xxx",
    }
  */
  if (typeof window.WeixinJSBridge == "undefined") {
      if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
      } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
      }
  } else {
      onBridgeReady(payload, callback_function);
  }
}

export {
  wechatPay
}
