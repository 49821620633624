<template>
<div class="main page">
  <orderUsageItem
    v-if="usage"
    v-bind:isCoachView="true"
    v-bind:is_designated="!!usage.designated_coach_id"
    v-bind:id="usage.id"
    v-bind:goodsName="usage.goods.name"
    v-bind:encrypt_id="usage.encrypt_id"
    v-bind:orderId="usage.orderId"
    v-bind:datetime="usage.datetime"
    v-bind:usage_duration="usage.usage_duration"
    v-bind:user_name="usage.user_name"
    v-bind:user_contact="usage.user_contact"
    v-bind:address="usage.address"
    v-bind:course_focus="usage.course_focus"
    v-bind:special_requirement="usage.special_requirement || '无'"
    v-bind:coach="usage.coach"
    v-bind:designatedCoach="usage.designated_coach"
    v-bind:status="usage.status"
    v-on:coachTakeUsage="coachTakeUsage($event)"
    v-on:coachRefuseUsage="coachRefuseUsage($event)"
    v-bind:hidden_buttons="true"
    class="order-usage-item"
  ></orderUsageItem>
	<!-- <slot name="top"></slot> -->

  <div class="recording-box">
    <div class="mainBox">
      <h3 class="recording-title">
        <template v-if="usage && usage.status == 1">🎙️ 服务已结束，停止录音</template>
        <template v-else>🎙️ 正在录音，请不要关闭页面</template>
      </h3>
      <div style="" class="ctrlProcessWave"></div>
      <div style="" class="ctrlProcessBar">
        <div class="ctrlProcessX" style="height:40px;background:#0B1;position:absolute;" :style="{width:powerLevel+'%'}"></div>
        <div class="ctrlProcessT" style="padding-left:50px; line-height:40px; position: relative;">{{ durationTxt }}</div>
      </div>
      <div class="weui-switch-cp" style="margin-top: 15px; height: 32px">
        <div class="weui-switch-cp__text" style="margin-right: 10px; float: left; line-height: 32px;">打开屏幕常亮</div>
        <input type="checkbox" class="weui-switch-cp__input" id="switchCP" />
        <div class="weui-switch-cp__box" id="turn-on-no-sleep" style="float: left;"></div>
      </div>
    </div>

    <div class="mainBox" hidden>
      <div class="pd">
        类型：{{ type }}
        <span style="margin:0 20px">
        比特率: <input type="text" v-model="bitRate" style="width:60px"> kbps
        </span>
        采样率: <input type="text" v-model="sampleRate" style="width:60px"> hz
      </div>

      <div class="btns">
        <div>
          <button @click="recOpen">打开录音,请求权限</button>
          <button @click="recClose">关闭录音,释放资源</button>
        </div>
        
        <button @click="recStart">录制</button>
        <button @click="recStop" style="margin-right:80px">停止</button>
        
        <span style="display: inline-block;">
          <button @click="recPause">暂停</button>
          <button @click="recResume">继续</button>
        </span>
        <span style="display: inline-block;">
          <button @click="recPlayLast">播放</button>
          <button @click="recUploadLast">上传</button>
          <button @click="recDownLast">本地下载</button>
        </span>
      </div>
    </div>
    
    <div class="mainBox" hidden>
      <!-- 放一个 <audio ></audio> 播放器，标签名字大写，阻止uniapp里面乱编译 -->
      <AUDIO ref="LogAudioPlayer" style="width:100%"></AUDIO>

      <div class="mainLog">
        <div v-for="obj in logs" :key="obj.idx">
          <div :style="{color:obj.color==1?'red':obj.color==2?'green':obj.color}">
            <!-- <template v-once> 在v-for里存在的bug，参考：https://v2ex.com/t/625317 -->
            <span v-once>[{{ getTime() }}]</span><span v-html="obj.msg"/>
            
            <template v-if="obj.res">
              {{ intp(obj.res.rec.set.bitRate,3) }}kbps
              {{ intp(obj.res.rec.set.sampleRate,5) }}hz
              编码{{ intp(obj.res.blob.size,6) }}b
              [{{ obj.res.rec.set.type }}]{{ obj.res.durationTxt }}ms 

              <button @click="recdown(obj.idx)">下载</button>
              <button @click="recplay(obj.idx)">播放</button>

              <span v-html="obj.playMsg"></span>
              <span v-if="obj.down">
                <span style="color:red">{{ obj.down }}</span>
                
                没弹下载？试一下链接或复制文本<button @click="recdown64(obj.idx)">生成Base64文本</button>

                <textarea v-if="obj.down64Val" v-model="obj.down64Val"></textarea>
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>

	<slot name="bottom"></slot>
</div>
</template>

<script>
//加载必须要的core，demo简化起见采用的直接加载类库，实际使用时应当采用异步按需加载
import Recorder from '../js/recorder/recorder-core'
//需要使用到的音频格式编码引擎的js文件统统加载进来，这些引擎文件会比较大
import '../js/recorder/mp3'
import '../js/recorder/mp3-engine'
//可选的扩展
import '../js/recorder/waveview'

import { getSingleUsageForCoach } from "../js/order_usage";
import orderUsageItem from '../components/order_usage_item';
import { DOMAIN } from '../js/api';
import NoSleep from 'nosleep.js';

var usage = null;

export default {
	data(){
		return {
			type:"mp3",
			bitRate:16, // 比特率，单位：kbps
			sampleRate:16000, // 采样率，单位：hz
			duration:0,
			durationTxt:"0",
			powerLevel:0,
			logs:[],
      SendInterval: 3000,
      realTimeSendTryTime: 0,
      realTimeSendTryNumber: 0,
      transferUploadNumberMax: 0,
      realTimeSendTryBytesChunks: [],
      realTimeSendTryClearPrevBufferIdx: 0,
      realTimeSendTryWavTestBuffers: [],
      realTimeSendTryWavTestSampleRate: 0,
      usageId: null,
      currentRoutePath: null,
      usage,
		}
	},
	created:function(){
		this.Rec=Recorder;
	},
  components: {
    orderUsageItem,
  },
  mounted: async function () {
    // NOTE: 这里只需要一个 Usage，但为了方便，复用了 getUsagesForCoach
    // 这里一定是搜索 status=0 的数据，表示正在服务中。
    this.usage = await getSingleUsageForCoach(this.$route.params.id);
    this.currentRoutePath = this.$route.path;
    this.usageId = this.$route.params.id;
    this.recOpen(); // 请求权限 && 开始录制
    this.refreshUsage();
    console.log("done");

    var noSleep = new NoSleep();
    document.getElementById('turn-on-no-sleep').addEventListener('click', function enableNoSleep() {
      if (document.getElementById('switchCP').checked) {
        document.getElementById('switchCP').checked = false;
        noSleep.disable();
      }
      else {
        document.getElementById('switchCP').checked = true;
        noSleep.enable();
      }
    }, false);
  },
	methods:{
		recOpen:function(){
			var This=this;
			var rec=this.rec=Recorder({
				type:This.type
				,bitRate:+This.bitRate
				,sampleRate:+This.sampleRate
				,onProcess:function(buffers,powerLevel,duration,sampleRate,newBufferIdx,asyncEnd){
					This.duration=duration;
					This.durationTxt=This.formatMs(duration,1);
					This.powerLevel=powerLevel;

					This.wave.input(buffers[buffers.length-1],powerLevel,sampleRate);
          This.RealTimeOnProcessClear(buffers,powerLevel,duration,sampleRate,newBufferIdx,asyncEnd);//实时数据处理，清理内存
				},takeoffEncodeChunk:function(chunkBytes){
          //接管实时转码，推入实时处理
          This.RealTimeSendTry(chunkBytes,false);
        }
			});

			rec.open(function(){
				This.reclog("已打开:"+This.type+" "+This.sampleRate+"hz "+This.bitRate+"kbps",2);
				This.wave=Recorder.WaveView({elem:".ctrlProcessWave"});
        This.recStart();
			},function(msg,isUserNotAllow){
				This.reclog((isUserNotAllow?"UserNotAllow，":"")+"打开失败："+msg,1);
			});
		},
    RealTimeOnProcessClear: function(buffers,powerLevel,bufferDuration,bufferSampleRate,newBufferIdx,asyncEnd){
      if(this.realTimeSendTryTime==0){
        this.realTimeSendTryTime=Date.now();
        this.realTimeSendTryNumber=0;
        this.transferUploadNumberMax=0;
        this.realTimeSendTryBytesChunks=[];
        this.realTimeSendTryClearPrevBufferIdx=0;
        this.realTimeSendTryWavTestBuffers=[];
        this.realTimeSendTryWavTestSampleRate=0;
      }

      // 这行日志只是为了避免 “is defined but never used” 的报错
      buffers,powerLevel,bufferDuration,bufferSampleRate,newBufferIdx,asyncEnd;

      //清理PCM缓冲数据，最后完成录音时不能调用stop，因为数据已经被清掉了
      //这里进行了延迟操作（必须要的操作），只清理上次到现在的buffer
      for(var i=this.realTimeSendTryClearPrevBufferIdx;i<newBufferIdx;i++){
        buffers[i]=null;
      }
      this.realTimeSendTryClearPrevBufferIdx=newBufferIdx;
      
      //备份一下方便后面生成测试wav
      for(i=newBufferIdx;i<buffers.length;i++){
        this.realTimeSendTryWavTestBuffers.push(buffers[i]);
      }
      this.realTimeSendTryWavTestSampleRate=bufferSampleRate;
    },
    RealTimeSendTry: function(chunkBytes,isClose){
      if(chunkBytes){//推入缓冲再说
        this.realTimeSendTryBytesChunks.push(chunkBytes);
      }

      if (this.checkIfRecordingShouldContinue() === false) {
        this.recClose();
        return;
      }

      var t1=Date.now();
      if(!isClose && t1-this.realTimeSendTryTime<this.SendInterval){
        return;//控制缓冲达到指定间隔才进行传输
      }

      this.realTimeSendTryTime=t1;
      var number=++this.realTimeSendTryNumber;

      //mp3缓冲的chunk拼接成一个更长点的mp3
      var len=0;
      for(var i=0;i<this.realTimeSendTryBytesChunks.length;i++){
        len+=this.realTimeSendTryBytesChunks[i].length;
      }

      var chunkData=new Uint8Array(len);
      var idx;
      for(i=0,idx=0;i<this.realTimeSendTryBytesChunks.length;i++){
        var chunk=this.realTimeSendTryBytesChunks[i];
        chunkData.set(chunk,idx);
        idx+=chunk.length;
      }
      this.realTimeSendTryBytesChunks=[];
      //推入传输
      var blob=null,meta={};
      if(chunkData.length>0){//mp3不是空的
        blob=new Blob([chunkData],{type:"audio/mp3"});
        meta=Recorder.mp3ReadMeta([chunkData.buffer],chunkData.length)||{};//读取出这个mp3片段信息
      }
      this.TransferUpload(number
        ,blob
        ,meta.duration||0
        ,{set:{
          type:"mp3"
          ,sampleRate:meta.sampleRate
          ,bitRate:meta.bitRate
        }}
        ,isClose
      )

      this.realTimeSendTryWavTestBuffers=[];
    },
    TransferUpload: function(number,blobOrNull,duration,blobRec,isClose){
      // console.log("--number:", number, "--blobOrNull:", blobOrNull, "--duration:", duration, "--blobRec:", blobRec, "--isClose:", isClose) 
      this.transferUploadNumberMax=Math.max(this.transferUploadNumberMax,number);
      if(blobOrNull){
        var blob=blobOrNull;
        var oReq = new XMLHttpRequest();
        oReq.open("POST", `${DOMAIN}/web_api/usages/${this.usageId}/recorder`, true);
        oReq.setRequestHeader("Content-Type", "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW");
        oReq.onload = function () {
          // console.log("oEvent", oEvent);
        }
        oReq.send(blob);
      }

      if(isClose){
        // Runtime.Log("No."+(number<100?("000"+number).substr(-3):number)+":已停止传输");
      }
    }
		,recClose:function(){
			var rec=this.rec;
			this.rec=null;
			if(rec){
				rec.close();
				this.reclog("已关闭");
			}else{
				this.reclog("未打开录音",1);
			}
		}
		,recStart:function(){
			if(!this.rec||!Recorder.IsOpen()){
				this.reclog("未打开录音",1);
				return;
			}
			this.rec.start();

			var set=this.rec.set;
			this.reclog("录制中："+set.type+" "+set.sampleRate+"hz "+set.bitRate+"kbps");
		}
		,recPause:function(){
			if(this.rec&&Recorder.IsOpen()){
				this.rec.pause();
			}else{
				this.reclog("未打开录音",1);
			}
		}
		,recResume:function(){
			if(this.rec&&Recorder.IsOpen()){
				this.rec.resume();
			}else{
				this.reclog("未打开录音",1);
			}
		}
		,recStop:function(){
			if(!(this.rec&&Recorder.IsOpen())){
				This.reclog("未打开录音",1);
				return;
			}
			
			var This=this;
			var rec=This.rec;
			rec.stop(function(blob,duration){
				This.reclog("已录制:","",{
					blob:blob
					,duration:duration
					,durationTxt:This.formatMs(duration)
					,rec:rec
				});
			},function(s){
				This.reclog("录音失败："+s,1);
			});
		}
		,recPlayLast:function(){
			if(!this.recLogLast){
				this.reclog("请先录音，然后停止后再播放",1);
				return;
			}
			this.recplay(this.recLogLast.idx);
		}
		,recUploadLast:function(){
			if(!this.recLogLast){
				this.reclog("请先录音，然后停止后再上传",1);
				return;
			}
			var This=this;
			var blob=this.recLogLast.res.blob;
			
			//本例子假设使用原始XMLHttpRequest请求方式，实际使用中自行调整为自己的请求方式
			//录音结束时拿到了blob文件对象，可以用FileReader读取出内容，或者用FormData上传
			var api="https://xx.xx/test_request";
			var onreadystatechange=function(title){
				return function(){
					if(xhr.readyState==4){
						if(xhr.status==200){
							This.reclog(title+"上传成功",2);
						}else{
							This.reclog(title+"没有完成上传，演示上传地址无需关注上传结果，只要浏览器控制台内Network面板内看到的请求数据结构是预期的就ok了。", "#d8c1a0");
							
							console.error(title+"上传失败",xhr.status,xhr.responseText);
						}
					}
				};
			};
			This.reclog("开始上传到"+api+"，请求稍后...","#f60");

			/***方式一：将blob文件转成base64纯文本编码，使用普通application/x-www-form-urlencoded表单上传***/
			var reader=new FileReader();
			reader.onloadend=function(){
				var postData="";
				postData+="mime="+encodeURIComponent(blob.type);//告诉后端，这个录音是什么格式的，可能前后端都固定的mp3可以不用写
				postData+="&upfile_b64="+encodeURIComponent((/.+;\s*base64\s*,\s*(.+)$/i.exec(reader.result)||[])[1]) //录音文件内容，后端进行base64解码成二进制
				//...其他表单参数
				
				var xhr=new XMLHttpRequest();
				xhr.open("POST", api);
				xhr.setRequestHeader("Content-Type","application/x-www-form-urlencoded");
				xhr.onreadystatechange=onreadystatechange("上传方式一【Base64】");
				xhr.send(postData);
			};
			reader.readAsDataURL(blob);

			/***方式二：使用FormData用multipart/form-data表单上传文件***/
			var form=new FormData();
			form.append("upfile",blob,"recorder.mp3"); //和普通form表单并无二致，后端接收到upfile参数的文件，文件名为recorder.mp3
			//...其他表单参数
			
			var xhr=new XMLHttpRequest();
			xhr.open("POST", api);
			xhr.onreadystatechange=onreadystatechange("上传方式二【FormData】");
			xhr.send(form);
		}
		,recDownLast:function(){
			if(!this.recLogLast){
				this.reclog("请先录音，然后停止后再下载",1);
				return;
			}
			this.recdown(this.recLogLast.idx);
		}
		,reclog:function(msg,color,res){
			var obj={
				idx:this.logs.length
				,msg:msg
				,color:color
				,res:res

				,playMsg:""
				,down:0
				,down64Val:""
			};
			if(res&&res.blob){
				this.recLogLast=obj;
			}
			this.logs.splice(0,0,obj);
		}
		,recdown64:function(idx){
			var o=this.logs[this.logs.length-idx-1];
			var reader = new FileReader();
			reader.onloadend = function() {
				o.down64Val=reader.result;
			};
			reader.readAsDataURL(o.res.blob);
		}
		,getTime:function(){
			var now=new Date();
			var t=("0"+now.getHours()).substr(-2)
				+":"+("0"+now.getMinutes()).substr(-2)
				+":"+("0"+now.getSeconds()).substr(-2);
			return t;
		}
		,formatMs:function(ms,all){
			var ss=ms%1000;ms=(ms-ss)/1000;
			var s=ms%60;ms=(ms-s)/60;
			var m=ms%60;ms=(ms-m)/60;
			var h=ms;
			var t=(h?h+":":"")
				+(all||h+m?("0"+m).substr(-2)+":":"")
				+(all||h+m+s?("0"+s).substr(-2)+".":"")
				+("00"+ss).substr(-3);
			return t;
		}
		,intp:function(s,len){
			s=s==null?"-":s+"";
			if(s.length>=len)return s;
			return ("_______"+s).substr(-len);
		}
    ,checkIfRecordingShouldContinue: function() {
      if (this.currentRoutePath != this.$route.path) {
        return false;
      }
      if (this.usage && this.usage.status == 1) {
        // 服务已完成，停止录音
        return false;
      }
      return true;
    },
    // 每隔5秒检测一次 Usage 的状态，如果已经完成，则停止录音
    refreshUsage: function() {
      var This = this;
      setInterval(function () {
        var usageId = This.$route.params.id;
        if (usageId == undefined || This.usage.status == 1) {
          return;
        }
        getSingleUsageForCoach(usageId).then(function (usage) {
          This.usage = usage;
        });
      }, 5000);
    },
	}
}
</script>

<style scoped>
.page {
  margin-top:15px;
  padding: 0 15px;
  margin-bottom: 15px;
  border-radius: 15px;
}
.order-usage-item {
  border-radius: 15px;
}
.order-usage-item::before,
.order-usage-item::after {
  border: 0;
}

.recorder-box {
  background: white;
  border-radius: 15px;
  padding: 16px;
}

.recording-box .mainBox {
  background: white;
  border-radius: 15px;
  padding: 16px;
  margin-bottom: 15px;
}

.ctrlProcessWave {
  height:100px;
  width:100%;
  border:1px solid #ccc;
  box-sizing: border-box;
  display:inline-block;
  vertical-align:bottom;
  border-radius: 10px 10px 0 0;
}
.ctrlProcessBar {
  height:40px;
  width:100%;
  display:inline-block;
  background:#ccc;
  position:relative;
  vertical-align:bottom;
  border-radius: 0 0 10px 10px;
}
.ctrlProcessX {
  border-radius: 0 0 0 10px;
}
.recording-title {
  margin-left: 5px;
  margin-bottom: 10px;
  color: #7f7f7f;
}
</style>