<template>
  <div class="gray-datetime">
    {{ datetime }}
  </div>
</template>

<script>

export default {
  name: "grayDatetime",
  props: {
    datetime: String,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.gray-datetime {
  color: #999;
  text-align: center;
  margin-bottom: 10px;
}
</style>
